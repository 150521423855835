import React from 'react'
import Navbar from '../Components/Common/Navbar'
import { Outlet } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import ProfileNav from '../Components/Profile/ProfileNav'
import Footer from '../Components/Footer/Footer'

const Profile = () => {
  return (
<>
<Navbar/>
<Grid container  sx={{height:'100vh', overflow:'scroll'}} className='hide_scrollbar'>
  <Grid item  lg={2} md={2} xs={0} sx={{display:{lg:'block', md:'block', sm:'none', xs:'none'}}}>
    <ProfileNav/>
  </Grid>
  <Grid item  lg={10}  md={10} sm={12} xs={12} sx={{}}>
    <Box sx={{bgcolor:'#f5f5f5',}}>

<Outlet/>
    </Box>
  </Grid>
</Grid>
<Footer/>
</>
  )
}

export default Profile