import React from "react";

import { Box, IconButton, InputLabel, Rating, Typography } from "@mui/material";
import { urlFor } from "../../../utils/SanityImageClient";


const BlogCard = ({data}) => {
  return (
    <>
      <Box
        sx={{
     
     borderRadius:3,
          transition: "0.2s all linear",
          "&:hover": {
            boxShadow:
              " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            height: "180px",
            background:` url('${data && urlFor(data?.image?.asset?._ref).url()}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
    
        </Box>
        <Box sx={{ p: 2 }}>
          <InputLabel sx={{ color: "#2b2b2b", fontWeight:600 }}>{data?.name}</InputLabel>
          <Typography sx={{ fontWeight: 200, fontSize: "12px" }} className="two-line-ellipsis">
{data?.description}
          </Typography>
          <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
                 <Typography sx={{ fontWeight: 400, fontSize: "10px" }} >
{formatDate(data?.date) || "--"}
          </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogCard;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  
  // Format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short', // 'Thur'
    month: 'short', // 'May'
    day: 'numeric' // '16'
  }).format(date);
  
  return formattedDate;
};
