import { Box, Grid, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { IoReceiptOutline } from "react-icons/io5";
import { RiUserSettingsLine } from "react-icons/ri";
import { CiCreditCard2 } from "react-icons/ci";
import { CgPassword } from "react-icons/cg";
import Settings from "../Settings/Settings";
import Payments from "../Settings/Payments";
import Transactions from "../Settings/Transactions";
import Passwords from "../Settings/Passwords";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Box sx={{ bgcolor: "#f5f5f5", pt: {lg:15, md:15, sm:14, xs:12}, px: {lg:4, md:4, sm:2, xs:2}, height: "100vh", overflow:'scroll' }}>
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize:{lg: "16px", md:'16px', sm:'14px', xs:'14px'} }}>
            Profile
          </Typography>
          <Typography sx={{ fontWeight: 300, mt: 1 , fontSize:{lg: "14px", md:'14px', sm:'12px', xs:'12px'},  }}>
            The information you provide is only shared with the restaurants you
            reserve. This is to help them coordinate with you better and ensure
            they are aware of your preferences.
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
           {
            isMobile ? (
              <>
             <Grid xs={12} item >
             <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" >
          <Tab label="Profile Settings" {...a11yProps(0)}  sx={{fontSize:'12px'}} />
          <Tab label="Wallet" {...a11yProps(1)}  sx={{fontSize:'12px'}}/>
          <Tab label="Transactions" {...a11yProps(2)}  sx={{fontSize:'12px'}}/>
          <Tab label="Password" {...a11yProps(3)} sx={{fontSize:'12px'}} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
    <Settings/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
 <Payments/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
   <Transactions/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
   <Passwords/>
      </CustomTabPanel>
    </Box>
             </Grid>
              </>
            ):(
         <>
               <Grid item lg={3} md={3} sx={{}}>
              <Box
                sx={{
                  border: "1px solid #dadada",
                  py: 3,
                  px: 2,
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  bgcolor: "#fff",
                }}
              >
                <MenuItem
                onClick={()=>setActiveStep(0)}
                selected={activeStep === 0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
                  <RiUserSettingsLine />
                  <Typography sx={{ fontWeight: 300 }}>
                    Profile Settings
                  </Typography>
                </MenuItem>
                <MenuItem
                  selected={activeStep === 1}
                      onClick={()=>setActiveStep(1)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
                  <CiCreditCard2 />
                  <Typography sx={{ fontWeight: 300 }}>
               Wallet
                  </Typography>
                </MenuItem>
                <MenuItem
                  selected={activeStep === 2}
                      onClick={()=>setActiveStep(2)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
                  <IoReceiptOutline />
                  <Typography sx={{ fontWeight: 300 }}>Transactions</Typography>
                </MenuItem>
                <MenuItem
                  selected={activeStep === 3}
                      onClick={()=>setActiveStep(3)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
                  <CgPassword />
                  <Typography sx={{ fontWeight: 300 }}>Password</Typography>
                </MenuItem>
              </Box>
            </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
              <Box
              className="hide_scrollbar"
                sx={{
                  border: "1px solid #dadada",
               
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  bgcolor: "#fff",
                  overflow:'scroll'

                }}
              >
                {activeStep === 0 && <Settings />}
                {activeStep === 1 && <Payments />}
                {activeStep === 2 && <Transactions />}
                {activeStep === 3 && <Passwords />}
              </Box>
            </Grid>
         </>
            )
           }
      
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
