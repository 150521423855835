import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import RestaurantCard from "../Common/RestaurantCard";
import { getBestRestaurants } from "../../../api";
import { Link } from "react-router-dom";

const Best = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleGetBest = async () => {
    setIsLoading(true);
    await getBestRestaurants()
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetBest();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          mt: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              component={"h3"}
              variant="h3"
              sx={{
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontWeight: 500,
              }}
            >
              Best restaurants in Lagos
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
              }}
            >
              We have all the best restaurants in all of Africa for you
            </Typography>
          </Box>
          <Box>
            <Link to="/restaurants/best-restaurants">
              <Button
                endIcon={<MdArrowRightAlt />}
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
                }}
              >
                See More{" "}
              </Button>
            </Link>
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                overflow: "scroll",
                width: "100%",
                columnGap: 2,
              }}
              className="hide_scrollbar"
            >
              {data?.splice(0, 8)?.map((restaurant, index) => (
                <Box key={index} sx={{ width: "60%", flexShrink: 0 }}>
                  <Link to={`/restaurant/${restaurant?.RestaurantName}`}>
                    <RestaurantCard restaurant={restaurant} />
                  </Link>
                </Box>
              ))}
            </Box>
          ) : (
            <Grid container spacing={3}>
              {data?.splice(0, 8)?.map((restaurant, index) => (
                <Grid item lg={3} md={3} sm={4} xs={6} key={index}>
                  <Link to={`/restaurant/${restaurant?.RestaurantName}`}>
                    <RestaurantCard restaurant={restaurant} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Best;
