import { IconButton, Box, Divider, LinearProgress } from '@mui/material'
import React, {useState, useEffect} from 'react';
import locatioIcon from '../../../../Assets/icons/location.svg'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CloseOutlined } from '@mui/icons-material';
import useGeolocation  from '../../../../utils/useGeolocation'
import { GrLocationPin } from "react-icons/gr";
import CustomAddress from '../../Custom/CustomAddress';
import { TiLocationArrow } from "react-icons/ti";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
boxSizing:'border-box',
  boxShadow: 24,
  p: 2,
  borderRadius:'10px'
};

const Location = ({handleUpdateAddress, button}) => {
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
const [isLoading, setIsLoading] = useState(false)

   const { coordinates, error, getCoordinates } = useGeolocation();
  const [address, setAddress] = useState("");

    useEffect(() => {
    getCoordinates();
  }, []);

  useEffect(() => {
    
    if (coordinates.latitude && coordinates.longitude) {
      setIsLoading(true)
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=AIzaSyBWlx74ECq1RzRGOhO64Uq0sQp8-i8UjfU`
      )
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false)
          if (data.results && data.results.length > 0) {
            setAddress(data.results[0].formatted_address);
          } else {
            setAddress("No address found");
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err);
          setAddress("Error fetching address");
        });
    }
  }, [coordinates]);

const [locationValue, setLocationValue]  = useState({
  address:""
})
const [long, setLong] = useState(coordinates?.longitude)
const [lat, setLat] = useState(coordinates?.latitude)
useEffect(()=>{
setLong(coordinates?.longitude)
setLat(coordinates?.latitude)
}, [coordinates])



const [addressSearch, setAddressSearch] = useState("")

    const handleLocationChange = (locationData) => {
    setLocationValue(locationData);
handleClose()
  };

  useEffect(()=>{

         setAddress(locationValue?.
address
)
setLong(locationValue?.longitude)
setLat(locationValue?.latitude)
  }, [locationValue])



  useEffect(()=>{
    handleUpdateAddress(long, lat)
  }, [address])

  // console.log(locationValue)
  return (
<>
{
  button ? (
    <>
    <Button startIcon={<TiLocationArrow />} onClick={handleOpen} sx={{height:'50px', border:'1px solid #d7d7d7', borderRadius:'10px'}} variant='outlined'>
      Select Location
    </Button>
    </>
  ):(
    <IconButton onClick={handleOpen}>
<img src={locatioIcon}/>
</IconButton>
  )
}

 <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Typography id="modal-modal-title"   sx={{fontSize:'18px', fontWeight:500}}>
           My Location
          </Typography>
          <IconButton         onClick={handleClose}>
            <CloseOutlined/>
          </IconButton>
      </Box>
      <Box sx={{mt:3}}>
    {
      isLoading  ? (
            <LinearProgress/>
        
      ):(
            <Typography sx={{fontSize:'12px',  mt:2, mb:2}}>{address}</Typography>
      )
    }
            <Divider sx={{mb:2}}/>
<CustomAddress
        onAddressChange={handleLocationChange}
                  updateAddress={addressSearch}
/>
      </Box>

      <Box sx={{mt:3}}>
      <Button  variant='outlined' fullWidth  sx={{borderRadius:'16px', py:1.5}} startIcon={<GrLocationPin />} onClick={()=>{
        getCoordinates()
        handleClose()
      }}>
        Use current location
      </Button>
  
      </Box>
    
        </Box>
      </Modal>
</>
  )
}

export default Location