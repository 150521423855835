import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  Popover,
  MenuItem,
  useMediaQuery,
  useTheme
} from "@mui/material";
import dp from '../../../Assets/icons/dp.svg';

import React, { useState } from "react";
import logo from "../../../Assets/logo/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import userAnime from "../../../Assets/anime/DxWRHmuFvb.json";
import { FaChevronRight } from "react-icons/fa";
import fork from "../../../Assets/icons/fork.svg";
import profileIcon from "../../../Assets/icons/profile.svg";
import headset from "../../../Assets/icons/headphone.svg";
import logoutIcon from "../../../Assets/icons/logout.svg";
import { logoutUser } from "../../../store/userSlice";
import Drawers from "./Drawers";



const Navbar = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userData = useSelector((state) => state.user?.user);
    const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () =>{
    handleClose()
    dispatch(logoutUser())
    // navigate("/")
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [params, setParams] = useState("")

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // console.log(userData)
  return (
    <>
      <AppBar sx={{ background: "#fff" }}>
        <Box
          sx={{
            width: { xl: "75%", lg: "85%" , xs:'90%'},
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70px",
      
          }}
        >
          <Link to="/">
            
            <img src={logo} width={isMobile ? 80 : 100} />
          </Link>
     {
      isMobile ? (
        <Drawers/>
      ):(
        <>
             <Box sx={{ width: "40%" }}>
            <TextField
            value={params}
            onChange={(e)=>setParams(e.target.value)}
              fullWidth
              placeholder="Search restaurants, cuisines or experience"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearch2Line />
                  </InputAdornment>
                ),
                endAdornment:params && (
          <InputAdornment position="end">
            <Link to={`/restaurant/find?Text=${params}`}>
            <Button  >
           Search
            </Button>
          </Link>
          </InputAdornment>
        ),
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: 4,
            }}
          >
            <a href="https://restaurant.reisty.com/" target="_blank">            <Typography color="primary">For Restaurant</Typography></a>

            {isAuthenticated ? (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 3 }}
                >
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: "8px",
                      bgcolor: "#BC172F",
                      width: "100px",
                    }}
                  >
                    <InputLabel
                      sx={{
                        color: "#fff",
                        fontSize: "10px",
                        textAlign: "center",
                      }}
                    >{`${userData?.first_name} ${userData?.last_name}`}</InputLabel>
                  </Box>
                  <IconButton
                    aria-describedby={id}
                    type="button"
                    onClick={handleClick}
                  >
          <Avatar src={dp}/>
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 3 }}
                >
                  <Link to="/login">
                    <Button
                      variant="outlined"
                      sx={{
                        border: "1px solid #dadada",
                        borderRadius: "10px",
                        height: "45px",
                        px: 3,
                        color: "#2b2b2b",
                        fontWeight: 400,
                        fontSize:'12px'
                      }}
                    >
                      Log in
                    </Button>
                  </Link>
                  <Link to="/signup">
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "10px",
                        height: "45px",
                        px: 4,
                        fontWeight: 400,
                             fontSize:'12px'
                      }}
                    >
                      Sign up
                    </Button>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </>
      )
     }
        </Box>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ py: 2, px: 1, width: "180px" }}>
          <Link to="/profile">
          <MenuItem sx={{}} onClick={handleClose}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
   <Avatar src={dp}/>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      color: "#565660",
                    }}
                  >
                    Hi, {userData?.first_name}
                  </Typography>
                  <Typography sx={{ color: "#717171", fontSize: "10px" }}>
                    see profile
                  </Typography>
                </Box>
              </Box>
              <FaChevronRight style={{ color: "#717171", fontSize: "12px" }} />
            </Box>
          </MenuItem>
          </Link>
          <Box sx={{ mt: 2 }}>
            <Link to="/profile/reservation">
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={fork} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Reservations
              </Typography>
            </MenuItem>
            </Link>
                <Link to="/profile">
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={profileIcon} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Account
              </Typography>
              
            </MenuItem>
            </Link>
            <Link to="/help"> 
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={headset} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Help & Support
              </Typography>
            </MenuItem>
            </Link>
            <MenuItem
            onClick={handleLogout}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={logoutIcon} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
             Logout
              </Typography>
            </MenuItem>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default Navbar;
