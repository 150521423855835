import { Box, Button, Typography , useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import imageBg from "../../../Assets/images/watermark.png";
import applebtn from "../../../Assets/icons/apple.svg";
import playstorebtn from "../../../Assets/icons/playstore.svg";
import iphone15 from "../../../Assets/images/iPhone 15.png";
import iphone14 from "../../../Assets/images/iPhone 14.png";
import { Link } from "react-router-dom";

const FooterHero = () => {
    const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Box
        sx={{
          mt: 8,
          bgcolor: "#BC172F",
            height: {lg:"400px", md:'400px', sm:'auto', xs:'auto'},
          background: `url('${imageBg}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: { xl: "75%", lg: "85%" , sm:'90%', xs:'90%'},
            height: "100%",
            margin: "0 auto",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
               flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'},
               mt:{lg:0, md:0, sm:4, xs:4},
          }}
        >
          <Box sx={{ width:{lg:"45%", md:'45%', sm:'100%', xs:'100%'} }}>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "optima",
                fontSize:{lg: "35px", md:'35px', sm:'30px', xs:'25px'},
                fontWeight: 700,
                lineHeight: "35px",
                textAlign:{lg:'left', md:'left', sm:'center', xs:'center'}
              }}
            >
              Discover the Top restaurants in Lagos on Reisty
            </Typography>
            <Typography
              sx={{
                mt: 2,
                color: "#fff",
            fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'12px'},
                bgcolor: "#BC172Fb7",
                           textAlign:{lg:'left', md:'left', sm:'center', xs:'center'}
              }}
            >
              Download the Reisty app now to access the finest restaurants,
              personalized experiences, and more. Available on the App Store and
              Google Play Store. Sign up for free and revolutionize your dining
              journey with Reisty.
            </Typography>

            <Box align={isMobile && "center"}  sx={{ mt: 3 }}>
              <a href="https://apps.apple.com/us/app/reisty/id6473653634">
              <Button
                sx={{
                  transition: "0.2s all ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <img src={applebtn} width={isMobile ? 100 : 120} />
              </Button>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.reisty.reisty">
              <Button
                sx={{
                  transition: "0.2s all ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <img src={playstorebtn} width={isMobile ? 100 : 120} />
              </Button></a>
            </Box>
          </Box>
          <Box
            sx={{
              width:{lg:"49%", md:'49%', sm:'100%', xs:'100%'},
              display: "flex",
              justifyContent: "right",
              alignItems: "end",
              height: "100%",
              overflow: "hidden",
              mt:{lg:0, md:0, sm:3, xs:3}
            }}
          >
            <img src={iphone15} width={isMobile ? 200 : 270} />
            <Box sx={{ ml: -15, mb: -2 }}>
              <img src={iphone14} width={isMobile ? 200 : 270}  />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FooterHero;
