import React ,{useState, useEffect} from "react";
import img1 from "../../../Assets/images/res1.avif";
import {
  Box,
  IconButton,
  InputLabel,
  Rating,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import 'react-lazy-load-image-component/src/effects/blur.css';
import { format } from 'date-fns';

const EventCard = ({ event, loading, noRatings }) => {
  

  


  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))


  return (
    <>
      {loading ? (
        <Box
   
          sx={{
            height: {lg:"180px", md:'180px', sm:'180px', xs:'180px'},
            border: "1px solid #dedede",
            transition: "0.2s all linear",
            "&:hover": {
              boxShadow:
                " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              cursor: "pointer",
            },
        
          }}
        >
          <Skeleton
            animation="wave"
            variant="square"
            sx={{ height:{lg:'100px', md:'100px', sm:'100px', xs:'100px'} }}
          />

          <Box sx={{ p: 2 }}>
            <InputLabel sx={{ color: "#2b2b2b" }}>
              <Skeleton animation="wave" />
            </InputLabel>
            <Typography sx={{ fontWeight: 200, fontSize: "12px" }}>
              <Skeleton animation="wave" sx={{ width: "60%" }} />
            </Typography>
            <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
          
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // height: "270px",
            border: "1px solid #dedede",
            transition: "0.2s all linear",
            "&:hover": {
              boxShadow:
                " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              cursor: "pointer",
              borderRadius:'8px'
            },
          }}
        >

              <Box className="carousel-2" sx={{height:{lg:'180px', md:'180px', sm:'160px', xs:'120px'}}}>
      <Box className="carousel-images-2" sx={{zIndex:-1000, backgroundImage:`url('${event?.EventImage}')`, backgroundSize:'cover'}}>

      


      </Box>


   


    </Box>
    
          <Box sx={{ p: isMobile ? 1 : 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 , justifyContent:'space-between'}}>
              <InputLabel sx={{ color: "#2b2b2b" , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontFamily:'optima', fontWeight:600}}>
                {event?.
EventName}{" "}
              </InputLabel>

              
            </Box>
            <Typography sx={{ fontWeight: 200, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
              {`${event?.
EventAddress} `}
            </Typography>
            <Box
              sx={{
                display: noRatings ? "none" : "flex",
                columnGap: 1,
                alignItems: "center",
              }}
            >
         
              <Typography sx={{ fontSize: "10px" }}>
           {DateTimeFormatter(event?.EventDate, event?.EventTime)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EventCard;


const DateTimeFormatter = (dateStr, timeStr) => {
  if(dateStr && timeStr) {
  const dateTime = new Date(`${dateStr}T${timeStr}`);

  // Format date as "Sun, Sep 29. 11:00 AM"
  const formattedDateTime = format(dateTime, "EEE, MMM d. h:mm a");

  return formattedDateTime
  }

  // Combine date and time

};
