import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import logo from "../../../Assets/logo/logo2.svg";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Box sx={{ bgcolor: "#2b2b2b", mt: 4 }}>
        <Box sx={{ width: { xl: "75%", lg: "85%", sm:'90%', xs:'90%' }, margin: "0 auto" }}>
          <Grid container spacing={4} sx={{ py: 5 }}>
            <Grid item lg={5}>
              <Box>
                <Link to="/">
                  <img src={logo}  width={isMobile ? 100 : 130}/>
                </Link>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Revolutionizing Hospitality with Reisty
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: 300,
                  }}
                >
                  Discover advanced solutions for the best dining experiences.
                  Reisty combines technology and hospitality, making it easier
                  for you to book, manage waitlists, and enjoy top-notch
                  restaurants in Lagos. Upgrade your food adventure with
                  Reisty's unique dining solutions.
                </Typography>
                <Box sx={{ mt: 3 }}>
                          <a href="https://www.facebook.com/profile.php?id=61551987707359&mibextid=ZbWKwL" target="_blank">
                  <IconButton>
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        bgcolor: "#333",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <FaFacebookF
                        style={{ color: "#fff", fontSize: "15px" }}
                      />
                    </Box>
                  </IconButton>
                  </a>
                          <a href="https://instagram.com/reistyapp?igshid=YTQwZjQ0NmI0OA==" target="_blank">
                  <IconButton>
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        bgcolor: "#333",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <FaInstagram
                        style={{ color: "#fff", fontSize: "15px" }}
                      />
                    </Box>
                  </IconButton>
                  </a>
                             <a href="https://www.linkedin.com/company/reisty/" target="_blank">
                  <IconButton>
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        bgcolor: "#333",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <BsTwitterX style={{ color: "#fff", fontSize: "15px" }} />
                    </Box>
                  </IconButton>
                  </a>
                        <a href="https://x.com/reistyapp?s=21" target="_blank">
                  <IconButton>
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        bgcolor: "#333",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <FaLinkedinIn
                        style={{ color: "#fff", fontSize: "15px" }}
                      />
                    </Box>
                  </IconButton>
                  </a>
                        <a href="https://x.com/reistyapp?s=21" target="_blank">
                  <IconButton>
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        bgcolor: "#333",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <FaYoutube
                        style={{ color: "#fff", fontSize: "15px" }}
                      />
                    </Box>
                  </IconButton>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box sx={{ display: "grid", gridTemplateColumns:isMobile ? "auto auto" :"auto auto auto auto", rowGap:3 }}>
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500, fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'},}}
                  >
                    Discover
                  </Typography>
                  <Box
                    sx={{ borderTop: "2px solid #fff", width: "50%", mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Link to="/restaurants/featured">
                      <Typography sx={{ color: "#fff",  fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Trending restuarants
                      </Typography>
                    </Link>
                    <Link to="/restaurants/best-restaurants">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Nearby restuarants
                      </Typography>
                    </Link>
                    <Link to="/restaurants/featured">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        New on reisty
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500,  fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'} }}
                  >
                    About
                  </Typography>
                  <Box
                    sx={{ borderTop: "2px solid #fff", width: "25%", mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Link to="/about">
                      <Typography sx={{ color: "#fff", fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>About us</Typography>
                    </Link>
                    <Link to="/terms">
                      <Typography sx={{ color: "#fff", mt: 2, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Terms and Conditions
                      </Typography>
                    </Link>
                    <Link to="/contact">
                      <Typography sx={{ color: "#fff", mt: 2, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Contact us
                      </Typography>
                    </Link>
                    <Link to="/privacy-policy">
                      <Typography sx={{ color: "#fff", mt: 2, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Privacy policy
                      </Typography>
                    </Link>
                    <Link to="/help">
                      <Typography sx={{ color: "#fff", mt: 2, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Support
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500,  fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'} }}
                  >
                    Top Facilities
                  </Typography>
                  <Box
                    sx={{ borderTop: "2px solid #fff", width: "50%", mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Link to="/restaurant/find?Text=Fine Dining">
                      <Typography sx={{ color: "#fff", fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300 }}>
                        Fine Dining
                      </Typography>
                    </Link>
                    <Link to="/restaurant/find?Text=Comfort food">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Comfort food
                      </Typography>
                    </Link>
                    <Link to="/restaurant/find?Text=Indian">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Indian
                      </Typography>
                    </Link>
                    <Link to="/restaurant/find?Text=French">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        French
                      </Typography>
                    </Link>
                    <Link to="/restaurant/find?Text=Barbecue">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Barbecue
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500, fontSize: "16px",  fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'} }}
                  >
                    For Restaurants
                  </Typography>
                  <Box
                    sx={{ borderTop: "2px solid #fff", width: "50%", mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <a href="https://restaurant.reisty.com/" target="_blank">
                      <Typography sx={{ color: "#fff" , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Reisty Overview
                      </Typography>
                    </a>
                    <a href="https://restaurant.reisty.com/pricing"  target="_blank">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Plans and Pricing
                      </Typography>
                    </a>
                    <a href="https://restaurant.reisty.com/#reservation_management" target="_blank">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Features
                      </Typography>
                    </a>
                    <a href="https://restaurant.reisty.com/customer-support" target="_blank">
                      <Typography sx={{ color: "#fff", mt: 2 , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, fontWeight:300}}>
                        Help Desk
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              borderTop: "1px solid #fff",
              pt: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 7,
               flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'}
            }}
          >
            <Typography sx={{ color: "#fff",  fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'10px'} }}>
              © {new Date().getFullYear()} Reisty. All rights reserved.
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", columnGap: 3, mt:isMobile && 3 }}>
              <Link to="/privacy-policy">
                <Typography sx={{ color: "#fff",  fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'10px'} }}>
                  Global Privacy Policy
                </Typography>
              </Link>
              <Link to="/terms">
                <Typography sx={{ color: "#fff",  fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'10px'} }}>
                  Terms of Service
                </Typography>
              </Link>
              <Link to="">
                <Typography sx={{ color: "#fff",  fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'10px'} }}>
                  Cookie Policy
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
