import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  Typography,
  Modal,
  IconButton,
    Select,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import { FaPeopleLine } from "react-icons/fa6";
import { GoClockFill } from "react-icons/go";
import { HiMiniUser } from "react-icons/hi2";
import { getCanceledReservation, getPast, getUpcoming } from "../../../../api";
import Loader from "../../Common/Loader";
import { RiStarSFill } from "react-icons/ri";
import empty1 from "../../../../Assets/icons/empty.svg";
import empty2 from "../../../../Assets/icons/empty2.svg";
import ManageReservations from "./Components/ManageReservations";
import { CloseOutlined } from "@mui/icons-material";
import infoIcon from '../../../../Assets/icons/info.svg'
import { Link } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
borderRadius:'10px',
  boxShadow: 24,
  p: 0,
  boxSizing:'border-box'
};

const Cancelled = () => {
  const [isLoading, setIsLoading] = useState(false);
   const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
    const [manageData, setManageData] = useState(null)
      const [open, setOpen] = React.useState(false);
  const handleOpen = (item) =>{
    setManageData(item)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);


  const handleGetUpcoming = async () => {
    setIsLoading(true);
    await getCanceledReservation()
      .then((res) => {
        setIsLoading(false);
        // console.log(res)
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err)
      });
  };


  useEffect(() => {
    handleGetUpcoming();

  }, []);
        const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  // console.log(data)
  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{}}>
        <Box sx={{ p: 2, bgcolor: "#f5f5f5" }}>
          <Typography
            sx={{ color: "#717171", fontWeight: 500, fontSize: "14px" }}
          >
          Cancelled Reservation
          </Typography>
        </Box>
        <Box align="center" sx={{ p: 2, bgcolor: "#fff", boxSizing:'border-box', }}>
          {!data || data?.length === 0 ? (
            <>
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={empty2} />
                <Typography sx={{ fontWeight: 500, mt: 2 }}>
                  No upcoming reservations
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  You have no upcoming reservations. After you are done enjoying
                  your reservations, they will show up here.
                </Typography>
                <Button
                  sx={{
                    border: "1px solid #d7d7d7",
                    py: 2,
                    mt: 3,
                    borderRadius: "10px",
                    px: 4,
                    color: "#717171",
                    fontSize: "12px",
                  }}
                >
                  Explore
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Grid container spacing={3}>
                {data?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )?.map((reservation, index) => (
                  <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #d7d7d7",
                        borderRadius: "10px",
                        p: 1,
                        boxSizing: "border-box",
                        width: "320px",
                        boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
                      }}
                    >
                      <Avatar
                        src={reservation?.RestaurantDetails?.ImageUrl}
                        variant="rounded"
                        sx={{ width: "100%", height: "120px", opacity:0.3 }}
                      />
                      <Box sx={{ mt: 2, opacity:0.3 }}>
                        <InputLabel sx={{ fontWeight: 500, color: "#151515" }}>
                          {reservation?.RestaurantDetails?.RestaurantName}
                        </InputLabel>
                        <Box
                          sx={{
                            display: "flex",
                            mt: 1,
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <FaCalendarDays />
                          <Typography
                            sx={{ fontSize: "12px", color: "#717171" }}
                          >
                            {reservation?.Date}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            mt: 1,
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <FaPeopleLine />
                          <Typography
                            sx={{ fontSize: "12px", color: "#717171" }}
                          >
                            {reservation?.Guest}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            mt: 1,
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <GoClockFill />
                          <Typography
                            sx={{ fontSize: "12px", color: "#717171" }}
                          >
                            {reservation?.Time}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            mt: 1,
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          <HiMiniUser />
                          <Typography
                            sx={{ fontSize: "12px", color: "#717171" }}
                          >
                            {reservation?.
UserFullName
}
                          </Typography>
                        </Box>

                    
                      </Box>
                          <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
<Link to={`/restaurant/${reservation?.RestaurantDetails?.RestaurantName}`} style={{width:'100%'}}>
                          <Button
                          fullWidth
                          
                            variant="contained"
                            sx={{
                              py: 1.5,
                              borderRadius: "8px",
                              fontSize: "12px",
                            }}
                          >
                         Book again
                          </Button>
                 </Link>
                        </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>

                    <Grid container sx={{ bgcolor: "#fff", mt: 3, px: {md:4, sm:2, xs:0}, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
      display:{md:'block', sm:'none', xs:'none'}
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data2?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
            </>
          )}
        </Box>

      </Box>



      
    </>
  );
};

export default Cancelled;
