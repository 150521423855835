import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import Popover from "@mui/material/Popover";

import Button from "@mui/material/Button";

const times = [
  "12:00",
  "12:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
];

const TimePicker = ({handleUpdateTime}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentTime = `${new Date().getHours().toString()
  }:00`
  const [selectedTime, setTime] = useState(currentTime);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [period, setPeriod] = React.useState("AM");

  const handlePeriod = (event, newAlignment) => {
    setPeriod(newAlignment);
  };


  useEffect(()=>{
    handleUpdateTime(`${selectedTime}`)
  }, [selectedTime])
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "80%",
          cursor:'pointer'
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <Box>
          <Typography sx={{ color: "#717171" ,     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>Time</Typography>
          <Typography sx={{ color: "#000", fontWeight: 500,     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'} }}>{selectedTime}</Typography>
        </Box>

        <IconButton>
          <MdArrowDropDown />
        </IconButton>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "300px", boxSizing: "border-box" }}>
          <Box sx={{ p: 2 }}>
            <ToggleButtonGroup
              size="small"
              value={period}
              exclusive
              onChange={handlePeriod}
            >
              <ToggleButton value={"AM"} sx={{    fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>AM</ToggleButton>
              <ToggleButton value={"PM"} sx={{    fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>PM</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ p: 2, pt: 0 }}>
            <Grid container spacing={1}>
              {times?.map((time, index) => (
                <Grid item lg={3}>
                  <ListItemButton
                    selected={time === selectedTime}
                    onClick={() => {
                      setTime(time)
                    handleClose()
                    }
                    }
                    sx={{
                      border: "1px solid #d7d7d7",
                      borderRadius: 2,
                      display: "grid",
                      placeItems: "center",
                      width: "100%",
                      ...(selectedTime === time && {
                        border: "1px solid #BC172F",
                      }),
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                      {time}
                    </Typography>
                  </ListItemButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default TimePicker;
