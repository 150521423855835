import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  Grid,
  ListItemButton,
  Button,
  InputLabel,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Calendar from "../../../Common/Calendar";
import { getOpeningHours, updateReservation } from "../../../../../api";
import CustomInput from "../../../Custom/CustomInput";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxSizing: "border-box",
  boxShadow: 24,
  p: 0,
  borderRadius: 5,
  overflow: "scroll",
  maxHeight: "90vh",
};

const ManageReservations = ({ open, onClose, data }) => {
    const { enqueueSnackbar } = useSnackbar();
  const [guest, setGuest] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openingHours, setOpeningHours] = useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [date, setDate] = useState(toDate);
  const [selectedTime, setSelectedTime] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [specialRequest, setSpecialRequest] = useState("");
  const [deitary, setDietary] = useState("");

    const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  

  useEffect(() => {
    const currentDate =  data ? new Date(data?.Date) :  new Date()
    setSpecialRequest(data?.SpecialRequest);
    setDietary(data?.DietaryRestriction);
    setDate(currentDate?.toISOString().split('T')[0]);
    setSelectedTime(data?.Time);
    setGuest(parseFloat(data?.Guest));
  }, [data]);

  const handleGetHours = async () => {
    setIsLoading(true);

    await getOpeningHours(data?.RestaurantDetails?.RestaurantId, date, guest)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          setOpeningHours(res?.data?.result[0]?.GroupedTime);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetHours();
  }, [date]);

  const handleUpdateReservation = async () => {
    setUpdateLoading(true);
    await updateReservation(data?.bookingId, guest, date, selectedTime, specialRequest, deitary)
    .then((res)=>{
         setUpdateLoading(false);
         if(res?.data?.status){
  handleAlert("success", `${res?.data?.success_message}`)
         }else{
             handleAlert("error", `${res?.data?.error_message}`)
         }
      // console.log(res)
    }).catch((err)=>{
         setUpdateLoading(false);
      // console.log(err)
    })
  };
  return (
    <>
    {
      updateLoading && <Loader/>
    }
      <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="hide_scrollbar" sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
            <Box sx={{ width: "75%" }}>
              <Typography variant="h6" sx={{ fontSize: "16px" }}>
                Modify Reservation
              </Typography>
              <Typography sx={{ fontSize: "10px", lineHeight: "15px" }}>
                You have the option to make changes to your Orchid Bistro
                reservation below. If you proceed, your original reservation
                will be cancelled and you will not incur any charges.
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "#f2f2f2",
              px: 3,
              border: "1px solid #dadada",
              py: 2,
            }}
          >
            <Typography
              sx={{
                borderBottom: "1px solid #BC172F",
                width: "fit-content",
                fontSize: "12px",
                color: "#717171",
              }}
            >
              RESERVATION
            </Typography>
            <Typography sx={{ color: "#565660", mt: 1 }}>
              {data?.RestaurantDetails?.RestaurantName}
            </Typography>
          </Box>
          {isLoading && <LinearProgress />}

          <Box sx={{ mt: 3, px: 3, mb: 5 }}>
            <Box
              sx={{
                border: "1px solid #dadada",
                p: 1,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography sx={{ color: "#717171", fontSize: "10px" }}>
                  Guest
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {" "}
                  {guest} Guest
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    if (guest > 1) {
                      setGuest((prev) => prev - 1);
                    }
                  }}
                  sx={{ borderRadius: "8px ", border: "1px solid #d7d7d7" }}
                >
                  <FaMinus style={{ fontSize: "17px" }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setGuest((prev) => prev + 1);
                  }}
                  sx={{
                    borderRadius: "8px ",
                    border: "1px solid #d7d7d7",
                    ml: 2,
                  }}
                >
                  <TiPlus style={{ fontSize: "17px" }} />
                </IconButton>
              </Box>
            </Box>

            <Accordion sx={{ mt: 2, borderRadius: "12px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box>
                  <Typography sx={{ fontSize: "12px" }}>Date</Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {" "}
                    {formatDate(date)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Calendar
                    future={false}
                    past={true}
                    onChildValue={(value) => {
                      setDate(value);
                    }}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: "12px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box>
                  <Typography sx={{ fontSize: "12px" }}>Time</Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {selectedTime}
                  </Typography>
                  {/* <LinearProgress/> */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Grid container spacing={2}>
                    {!openingHours ||
                    openingHours.length === 0 ||
                    (openingHours[0].length === 0 &&
                      openingHours[1]?.length === 0) ? (
                      <>
                        <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              color: "#BC172F",
                            }}
                          >
                            No Available Time for the selected date
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: 300,
                              fontSize: "12px",
                            }}
                          >
                            You can try selecting another date
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        {openingHours &&
                          openingHours[0]?.map((time, index) => {
                            return (
                              <Grid item lg={3} key={index}>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedTime(time?.Time);
                                  }}
                                  selected={selectedTime === time?.Time}
                                  sx={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "8px",
                                    "&.Mui-selected": {
                                      border: "1px solid #BC172F",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    {time?.Time}
                                  </Typography>
                                </ListItemButton>
                              </Grid>
                            );
                          })}
                        {openingHours &&
                          openingHours[1]?.map((time, index) => {
                            return (
                              <Grid item lg={3} key={index}>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedTime(time?.Time);
                                  }}
                                  selected={selectedTime === time?.Time}
                                  sx={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    {time?.Time}
                                  </Typography>
                                </ListItemButton>
                              </Grid>
                            );
                          })}
                      </>
                    )}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ mt: 3 }}>
              <InputLabel sx={{ fontSize: "12px" }}>Special Request</InputLabel>
              <CustomInput
                fullWidth
                multiline
                rows={2}
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}
              />
              <InputLabel sx={{ mt: 2, fontSize: "12px" }}>
                Dietary Restriction
              </InputLabel>
              <CustomInput
                fullWidth
                multiline
                rows={2}
                value={deitary}
                onChange={(e) => setDietary(e.target.value)}
              />
            </Box>
          </Box>

          <Box sx={{ p: 3 }}>
            <Button
            onClick={handleUpdateReservation}
              variant="contained"
              sx={{ py: 2, borderRadius: "10px" }}
              fullWidth
            >
              Update Reservation
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManageReservations;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
