import React, { useState, useEffect } from "react";

import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { MdViewCarousel } from "react-icons/md";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { addToFavourite } from "../../../api";
import defaultImg from '../../../Assets/images/default.png'

const Carousel = ({ images, title, description, id }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [openGallery, setOpenGallery] = React.useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images?.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images?.length, 6000]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: description,
          url: window.location.href,
        });
        // console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  const [favLoading, setFavLoading] = useState(false);
  const [favSuccess, setFavSuccess] = useState(false);
  const handleAddToFav = async () => {
    setFavLoading(true);
    setFavSuccess(false);
    await addToFavourite(id)
      .then((res) => {
        setFavLoading(false);
        setFavSuccess(true);
        // console.log(res);
      })
      .catch((err) => {
        setFavLoading(false);
        // console.log(err);
      });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleImageError = (event) => {
    event.target.src = defaultImg;
  };

  return (
    <>
      <Box
        className="carousel"
        sx={{ height: { lg: "400px", md: "400px", sm: "300px", xs: "250px" } }}
      >
        <Box className="carousel-images">
          {images?.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl?.Link}
              onError={handleImageError}
              alt={`Slide ${index}`}
              className={`carousel-image ${
                index === activeIndex ? "active" : ""
              }`}
            />
          ))}
        </Box>

        <Box>
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: -6,
                px: 3,
              }}
            >
              <IconButton
                sx={{ bgcolor: "#fff", borderRadius: "8px" }}
                onClick={() => setOpenGallery(true)}
              >
                <MdViewCarousel style={{ color: "#333", fontSize: "14px" }} />
              </IconButton>
              <Box>
                <IconButton
                  onClick={handleAddToFav}
                  sx={{ bgcolor: "#fff", borderRadius: "8px" }}
                >
                  {favLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <>
                      {favSuccess ? (
                        <IoMdHeart
                          style={{ color: "#BC172F", fontSize: "14px" }}
                        />
                      ) : (
                        <IoMdHeartEmpty
                          style={{ color: "#BC172F", fontSize: "14px" }}
                        />
                      )}
                    </>
                  )}
                </IconButton>
                <IconButton
                  sx={{ bgcolor: "#fff", borderRadius: "8px", ml: 1 }}
                  onClick={handleShare}
                >
                  <AiOutlineShareAlt
                    style={{ color: "#BC172F", fontSize: "14px" }}
                  />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", mt: -9, pl: 3 }}>
              <IconButton
                sx={{ bgcolor: "#fff", borderRadius: "5px" }}
                onClick={() => setOpenGallery(true)}
              >
                <MdViewCarousel style={{ color: "#333", fontSize: "27px" }} />
              </IconButton>
              {/* <Box className="carousel-dots">
        {images?.map((_, index) => (
          <Box
            key={index}
            className={`carousel-dot ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Box> */}
            </Box>
          )}
        </Box>


      </Box>
      <Lightbox
        open={openGallery}
        plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        close={() => setOpenGallery(false)}
        slides={images?.map((photo) => ({ src: photo.Link }))}
      />
    </>
  );
};

export default Carousel;
