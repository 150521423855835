import { Box, List, ListItem, ListItemButton, Stack } from "@mui/material";
import React from "react";
import fork from "../../../Assets/icons/fork.svg";
import profileIcon from "../../../Assets/icons/profile.svg";
import headset from "../../../Assets/icons/headphone.svg";
import logoutIcon from "../../../Assets/icons/logout.svg";
import { useLocation } from "react-router-dom";
import { GiHotMeal } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { TbHelpTriangleFilled } from "react-icons/tb";
import Footer from "../Footer/Footer";

const NavData = [
  {
    title: "Reservation",
    icon: <GiHotMeal />,
    path: "/profile/reservation",
  },
  {
    title: "Profile",
    icon: <ImProfile />,
    path: "/profile",
  },
  {
    title:"Help & Support",
    icon:<TbHelpTriangleFilled />,
    path:'/profile/help-support'
  },
];

const ProfileNav = () => {
  return (
    <>
      <Box
        sx={{
          height: "100%",
          borderRight: "1px solid #dadada",
          p: 2,
          boxSizing: "border-box",
          bgcolor: "#f1f1f1",
          pt: 10,
        }}
      >
        <List>
          <Stack component="nav" spacing={3} sx={{ px: 2, mt: 7 }}>
            {NavData.map((item) => (
              <NavItem key={item.title} item={item} />
            ))}
            <Box>{/* <Faqs /> */}</Box>
          </Stack>
        </List>
      </Box>

    </>
  );
};

export default ProfileNav;

function NavItem({ item }) {
  const { pathname } = useLocation();

  const active = item.path === pathname;

  return (
    <ListItemButton
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#8F8F8F",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#bc172f",
          fontWeight: "fontWeightSemiBold",
          bgcolor: "#BC172F1a",
          borderLeft: "2px solid  #bc172f",
          "&:hover": {
            bgcolor: "",
          },
        }),
      }}
    >
      <Box component="span" sx={{height: 24, mr: 1, fontSize:'18px' }}>
    {item?.icon}
      </Box>

      <Box
        component="span"
        sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
      >
        {item.title} {item?.count && <Box></Box>}
      </Box>
    </ListItemButton>
  );
}
