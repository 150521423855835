import { InputLabel } from '@mui/material'
import React from 'react'

const CustomLabel = ({label}) => {
  return (
          <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
              {label}
              </InputLabel>
  )
}

export default CustomLabel