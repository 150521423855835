import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  TextField,
  Grid,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import axios from "axios";

const GOOGLE_MAPS_API_KEY = "AIzaSyBWlx74ECq1RzRGOhO64Uq0sQp8-i8UjfU";

function loadScript(src, position, id) {
  return new Promise((resolve, reject) => {
    if (!position) {
      reject(new Error("No position provided for script"));
      return;
    }

    const existingScript = document.getElementById(id);
    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    position.appendChild(script);
  });
}

const CustomAddress = ({ onAddressChange, updateAddress }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [locationValue, setLocationValue] = useState();
  const loaded = useRef(false);
  const autocompleteService = useRef(null);

  useEffect(() => {
    if (updateAddress) {
      setValue(updateAddress);
    }
  }, [updateAddress]);

  useEffect(() => {
    const loadGoogleMapsScript = async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector("head"),
          "google-maps"
        );

        if (typeof window.google !== "undefined" && window.google.maps && window.google.maps.places) {
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
          loaded.current = true;
        }
      } catch (error) {
        console.error("Error loading Google Maps script:", error);
      }
    };

    if (!loaded.current) {
      loadGoogleMapsScript();
    }
  }, []);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleGeocodeByPlaceId = async (placeId) => {
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_MAPS_API_KEY}`
      );
      const data = res.data;
      setLocationValue(data.results[0].geometry.location);

      onAddressChange({
        address: data.results[0].formatted_address,
        longitude: data.results[0].geometry.location.lng,
        latitude: data.results[0].geometry.location.lat,
      });
    } catch (err) {
      console.error("Error fetching geocode data:", err);
    }
  };

  const handleAddressChange = (newValue) => {
    if (newValue && newValue.place_id) {
      handleGeocodeByPlaceId(newValue.place_id);
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleAddressChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Find a location"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: <>{params.InputProps.startAdornment}</>,
            style: {
              ...params.InputProps.style,
              fontFamily: "outfit",
              fontSize: "13px",
              borderRadius: "10px",
              offset: "1px solid #ccc",
              color: "#151515",
              border: "1px solid #151515",
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon
                  sx={{ color: "text.secondary", fontSize: "18px" }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: part.highlight ? "bold" : "regular",
                      fontSize: "13px",
                      fontFamily: "outfit",
                    }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontFamily: "outfit" }}
                >
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default CustomAddress;
