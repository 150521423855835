import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#BC172F",
    },
  },

  typography: {
    fontFamily: "outfit",

    h1: {
      fontSize: "32px",

      fontWeight: 900,
      color: "#fff",
    },
    h2: {
      fontSize: "24px",

      fontWeight: 700,
      fontFamily: "butler",
    },
    h3: {
      fontSize: "20px",

      fontWeight: 700,
      fontFamily: "outfit",
    },
    h6: {
      fontSize: "12px",

      fontWeight: 700,
      fontFamily: "outfit",
    },
    body1: {
      fontSize: "14px",

      fontWeight: 400,
      fontFamily: "outfit",
      lineHeight: "20px",
    },
    body2: {
      fontSize: "13px",

      fontWeight: 400,
      fontFamily: "outfit",
    },
    subtitle1: {
      fontSize: "13px",

      fontWeight: 500,
      fontFamily: "outfit",
    },
    subtitle2: {
      fontSize: "12px",
 
      fontWeight: 500,
      fontFamily: "outfit",
    },
    button: {
      textTransform: "initial",
      fontFamily: "outfit",
  //     "disabled": {
  //   "background": "#5b5b5b"
  // }
    },
  },
});

export default Theme;
