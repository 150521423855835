import { Box, Typography ,useTheme, useMediaQuery} from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import bgImg from "../../../Assets/images/bg.webp";
import bgMobile from "../../../Assets/images/bg-mobile.webp";
import anime from "../../../Assets/anime/aUErp1muSy.json";
import FullSearchBar from "./Components/FullSearchBar";
import MobileFullSearch from "./Components/MobileFullSearch";

const Banner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
{
  isMobile ? (
    <>
      <Box
        sx={{
          mt: 9,
          bgcolor: "#333",
          height: "500px",
          background: `url('${bgMobile}')`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
        variant="h1"
        component={"h1"}
          sx={{
            color: "#fff",
            fontWeight: 700,
            fontSize: {lg:"54px", md:'52px',sm:'25px', xs:'25px'},
            fontFamily: "optima",
            textAlign: "center",
            width: { xl: "65%", lg: "75%", xs:'90%' },
            lineHeight: {lg:"70px", md:'70px', sm:'30px', xs:'30px'},
          }}
        >
          Discover the Best Restaurants in Lagos for Every Occasion
        </Typography>
        <Typography
        variant="body1"

        component={"p"}
          sx={{
            color: "#fff",
            fontSize: {lg:"18px", md:'18px', sm:'16px',xs:'14px'},
            textAlign: "center",
            width: { xl: "65%", lg: "75%" , xs:'90%'},
            fontWeight: 400,
            lineHeight: {lg:"30px", md:'30px', sm:'20px', xs:'20px'},
            mt: 2,
            fontWeight:300
          }}

        >
          Find the Perfect Table. Book using Reisty, the simplest way to book
          your best restaurant experience.
        </Typography>
        <Box sx={{ width: "80%", margin: "0 auto", mt: 6 }}>
         <MobileFullSearch/>
        </Box>
      </Box>
    </>
  ):(
    <>
      <Box
        sx={{
          mt: 9,
          bgcolor: "#333",
          height: "600px",
          background: `url('${bgImg}')`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: 700,
            fontSize: {lg:"54px", md:'52px',sm:'45px', xs:'45px'},
            fontFamily: "optima",
            textAlign: "center",
            width: { xl: "65%", lg: "75%", xs:'90%' },
            lineHeight: {lg:"70px", md:'70px', sm:'60px', xs:'60px'}
          }}
        >
          Discover the Best Restaurants in Lagos for Every Occasion
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontSize: {lg:"18px", md:'18px', sm:'16px',xs:'14px'},
            textAlign: "center",
            width: { xl: "65%", lg: "75%" , xs:'90%'},
            fontWeight: 400,
            lineHeight: {lg:"30px", md:'30px', sm:'20px', xs:'20px'},
            mt: 2,
          }}
        >
          Find the Perfect Table. Book using Reisty, the simplest way to book
          your best restaurant experience.
        </Typography>
        <Box sx={{ width: "80%", margin: "0 auto", mt: 6 }}>
          <FullSearchBar />
        </Box>
      </Box>
    </>
  )
}
    </>
  );
};

export default Banner;
