import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import imgbg from "../../../../Assets/images/res1.avif";
import rateIcon from "../../../../Assets/icons/fav.svg";
import { MdLocationOn } from "react-icons/md";
import empty2 from "../../../../Assets/icons/empty2.svg";

const Favourites = () => {
  const [data, setData]  = useState([1, 2])
  return (
    <>
      <Box sx={{ bgcolor: "#f5f5f5", p: 3 }}>
 {
  (!data ||  data?.length === 0) ?  (
    <>
       <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor:'#fff'
                }}
              >
                <img src={empty2} />
                <Typography sx={{ fontWeight: 500, mt: 2 }}>
            No saved reservations
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                 You have no saved reservations.
                </Typography>
                <Button
                  sx={{
                    border: "1px solid #d7d7d7",
                    py: 2,
                    mt: 3,
                    borderRadius: "10px",
                    px: 4,
                    color: "#717171",
                    fontSize: "12px",
                  }}
                >
                  Explore
                </Button>
              </Box>
    </>
  ):(
    <>
           <Grid container spacing={2}>
          <Grid item lg={6}>
            <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: "10px" }}>
              <Box
                sx={{
                  background: `url('${imgbg}')`,
                  backgroundSize: "cover",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 500 }}>
                    The Orchid Bistro
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <MdLocationOn style={{ color: "#BC172F" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      Akoka Lagos
                    </Typography>
                  </Box>
                </Box>
                <img src={rateIcon} alt="icon" />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  border: "1px solid #d7d7d7",
                  borderRadius: "10px",
                  p: 2,
                  height: "60px",
                }}
              ></Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: "10px" }}>
              <Box
                sx={{
                  background: `url('${imgbg}')`,
                  backgroundSize: "cover",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 500 }}>
                    The Orchid Bistro
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <MdLocationOn style={{ color: "#BC172F" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      Akoka Lagos
                    </Typography>
                  </Box>
                </Box>
                <img src={rateIcon} alt="icon" />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  border: "1px solid #d7d7d7",
                  borderRadius: "10px",
                  p: 2,
                  height: "60px",
                }}
              ></Box>
            </Box>
          </Grid>
        </Grid>
    </>
  )
 }
      </Box>
    </>
  );
};

export default Favourites;
