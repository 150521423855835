import img1 from '../icons/Bookinng/business.svg'
import img2 from '../icons/Bookinng/anniversary.svg'
import img3 from '../icons/Bookinng/graduation.svg'
import img4 from '../icons/Bookinng/birthday.svg';
import act1 from  '../icons/Bookinng/Active/business.svg'
import act2 from  '../icons/Bookinng/Active/anniversary.svg'
import act3 from  '../icons/Bookinng/Active/graduation.svg'
import act4 from  '../icons/Bookinng/Active/birthday.svg'



 const BookingData = [
{
  title:'Business',
  icon1:img1,
  icon2:act1
},
{
  title:'Anniversary',
  icon1:img2,
  icon2:act2
},
{
  title:'Graduation',
  icon1:img3,
  icon2:act3
},
{
  title:'Birthday',
  icon1:img4,
  icon2:act4
},
]

export default  BookingData