import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Footer/Footer";
import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Autocomplete,
  Grid,
} from "@mui/material";
import logo from "../../../Assets/logo/logo-icon-red.svg";
import GoogleIcon from "../../../Assets/icons/gooogle.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Countries } from "../../../Assets/Dataa/Countries";
import { Link, useNavigate } from "react-router-dom";
import GoogleSignup from "../../Components/Auth/GoogleSignup";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import { createUser } from "../../../api";
import Loader from "../../Components/Common/Loader";

const clientId =process.env.REACT_APP_GOOGLE_CLIENT_ID

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await createUser(
      values?.email,
      values?.phoneNumber,
      values?.password,
      values?.confirmPassword,
      "",
      values?.firstName,
      values?.lastName,
      selectedCountry?.label
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          navigate(`/verify-email/${values?.email}`);
        } else {
          handleAlert(
            "error",
            `${res?.data?.error_message}-${res?.data?.result}`
          );
        }
        // console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
        // console.log(err);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      {/* <Navbar /> */}
      <Box
        sx={{
          mt: { lg: 15, md: 15, sm: 12, xs: 10 },
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            width: {xl:'30%', lg: "40%", md: "50%", sm: "80%", xs: "90%" },
            boxShadow:
              " 0 2.8px 2.2px rgba(49, 43, 65, .034), 0 6.7px 5.3px rgba(49, 43, 65, .048), 0 12.5px 10px rgba(49, 43, 65, .06), 0 22.3px 17.9px rgba(49, 43, 65, .072), 0 41.8px 33.4px rgba(49, 43, 65, .086), 0 100px 80px rgba(49, 43, 65, .12)",
            mb: 10,
            borderRadius: "10px",
            py: 6,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
                        border:'1px solid #e6e7eb'
          }}
        >
          <Box
            align="center"
            sx={{ width: { lg: "80%", md: "80%", sm: "80%", xs: "80%" } }}
          >
                  <Link to="/">
            <img src={logo} alt="reisty_logo" /></Link>
            <Typography sx={{ mt: 1, fontSize: "12px", textAlign: "center" }}>
              Sign up for a free account create and manage all <br />
              your reservations
            </Typography>
            <GoogleOAuthProvider clientId={clientId}>
              <GoogleSignup />
            </GoogleOAuthProvider>
          </Box>

          <Box
            sx={{
              my: 3,
              width: { lg: "80%", md: "80%", sm: "85%", xs: "90%" },
            }}
          >
            <Divider>or</Divider>
          </Box>

          <Box sx={{ width: { lg: "85%", md: "80%", sm: "85%", xs: "90%" } }}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "12px",
                    fontFamily: "outfit",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  First Name
                </InputLabel>
                <TextField
                  value={values.firstName}
                  onChange={handleInputChange}
                  name="firstName"
                  placeholder="   First Name"
                  margin="dense"
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "12px",
                      borderRadius: "10px",
                      offset: " 1px solid #737373",
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "12px",
                    fontFamily: "outfit",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  Last Name
                </InputLabel>
                <TextField
                  value={values.lastName}
                  onChange={handleInputChange}
                  name="lastName"
                  placeholder=" Last Name"
                  margin="dense"
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "12px",
                      borderRadius: "10px",
                      offset: " 1px solid #737373",
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "12px",
                    fontFamily: "outfit",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  Email
                </InputLabel>
                <TextField
                  value={values.email}
                  onChange={handleInputChange}
                  name="email"
                  placeholder=" youremail@gmail.com"
                  margin="dense"
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "12px",
                      borderRadius: "10px",
                      offset: " 1px solid #737373",
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "12px",
                    fontFamily: "outfit",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  Phone Number
                </InputLabel>
                <TextField
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                  name="phoneNumber"
                  placeholder="Phone Number "
                  margin="dense"
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "12px",
                      borderRadius: "10px",
                      offset: " 1px solid #737373",
                      fontWeight: 500,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "12px",
                    fontFamily: "outfit",
                    textAlign: "left",
                  }}
                >
                  Country of residence{" "}
                  <span style={{ color: "#BC172F" }}>*</span>
                </InputLabel>
                <Autocomplete
                  id="country-select-demo"
                  sx={{
                    width: "100%",
                    mt: 1,
                    "& div": { borderRadius: "10px" },
                  }}
                  options={Countries}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        fontFamily: "outfit",
                        borderRadius: "10px",
                        fontSize: "12px",
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Please Select"
                      {...params}
                      fullWidth
                      inputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "12px",
                        },
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "12px",
                fontFamily: "outfit",
                textAlign: "left",
                mt: 2,
                fontWeight: 500,
              }}
            >
              Password*
            </InputLabel>
            <TextField
              value={values.password}
              onChange={handleInputChange}
              name="password"
              placeholder="**********"
              margin="dense"
              size="large"
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "12px",
                  borderRadius: "10px",
                  offset: " 1px solid #737373",
                  fontWeight: 500,
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "12px",
                fontFamily: "outfit",
                textAlign: "left",
                mt: 2,
                fontWeight: 500,
              }}
            >
              Confirm Password*
            </InputLabel>
            <TextField
              value={values.confirmPassword}
              onChange={handleInputChange}
              name="confirmPassword"
              placeholder="**********"
              margin="dense"
              size="large"
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "12px",
                  borderRadius: "10px",
                  offset: " 1px solid #737373",
                  fontWeight: 500,
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography sx={{ fontSize: "12px", textAlign: "left" }}>
              By clicking the 'Create Account' button below, you agree to the
              Reisty
              <Link to="/terms" style={{ color: "#BC172F", fontWeight: 400 }}>
                {" "}
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link to="/privacy-policy" style={{ color: "#BC172F", fontWeight: 400 }}>
                Privacy Policy
              </Link>
              .
            </Typography>

            <Button
              fullWidth
              variant="contained"
              sx={{ py: 2, mt: 2, borderRadius: "10px" }}
              onClick={handleSubmit}
              disabled={
                !values?.firstName ||
                !values?.lastName ||
                !values?.email ||
                !values?.phoneNumber ||
                !values?.password ||
                !values?.confirmPassword
              }
            >
              Create Account
            </Button>
          </Box>

          <Box sx={{ my: 2, width: "70%" }}>
            <Divider />
          </Box>
          <Typography sx={{ fontSize: "12px", textAlign: "left" }}>
            Already have an account?
            <Link
              to="/login"
              style={{
                color: "#BC172F",
                fontWeight: 400,
                marginLeft: "10px",
              }}
            >
              Login
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export default Signup;
