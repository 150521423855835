import { Box, Grid, MenuItem, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { IoMdHeart } from "react-icons/io";
import { GiHotMeal } from "react-icons/gi";
import { MdBookmarkAdded } from "react-icons/md";
import { MdCancel } from "react-icons/md";

import Upcoming from "../Reservation/Upcoming";
import Saved from "../Reservation/Saved";
import Favourites from "../Reservation/Favourites";
import Cancelled from "../Reservation/Cancelled";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Reservation = () => {
  const [activeStep, setActiveStep] = useState(0);
      const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Box sx={{ bgcolor: "#f5f5f5", pt: {lg:13, md:13, sm:10, xs:9}, px: {lg:4, md:4, sm:2, xs:2}, height: "100vh", overflow:'scroll' }}>


        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {
              isMobile ? (
                <>
                           <Grid xs={12} item >
             <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" >
          <Tab label="Reservations" {...a11yProps(0)}  sx={{fontSize:'12px'}} />
          <Tab label="     Saved" {...a11yProps(1)}  sx={{fontSize:'12px'}} disabled/>
          <Tab label="Favourites" {...a11yProps(2)}  sx={{fontSize:'12px'}} disabled/>
          <Tab label="Cancelled" {...a11yProps(3)} sx={{fontSize:'12px'}} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
    <Upcoming/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
 <Saved/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
   <Favourites/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
   <Cancelled/>
      </CustomTabPanel>
    </Box>
             </Grid>  
                </>
              ):(
                <>
                      <Grid item lg={3} md={3}>
              <Box
                sx={{
                  border: "1px solid #dadada",
                  py: 3,
                  px: 2,
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  bgcolor: "#fff",
                }}
              >
                <MenuItem
                onClick={()=>setActiveStep(0)}
                selected={activeStep === 0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
<GiHotMeal style={{color:activeStep === 0 ? "#BC172F" : "#151515"}} />
                  <Typography sx={{ fontWeight: 300 , color:activeStep === 0? "#BC172F" : "#151515"}}>
                Reservations
                  </Typography>
                </MenuItem>
                <MenuItem
                disabled
                  selected={activeStep === 1}
                      onClick={()=>setActiveStep(1)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
      <MdBookmarkAdded  style={{color:activeStep === 1 ? "#BC172F" : "#151515"}}/>
                  <Typography sx={{ fontWeight: 300, color:activeStep === 1 ? "#BC172F" : "#151515" }}>
              Saved
                  </Typography>
                </MenuItem>
                <MenuItem
                disabled
                  selected={activeStep === 2}
                      onClick={()=>setActiveStep(2)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
              <IoMdHeart  style={{color:activeStep === 2 ? "#BC172F" : "#151515"}}/>
                  <Typography sx={{ fontWeight: 300, color:activeStep === 2 ? "#BC172F" : "#151515" }}>Favourites</Typography>
                </MenuItem>
                <MenuItem
                  selected={activeStep === 3}
                      onClick={()=>setActiveStep(3)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    py: 1,
                    mb: 2,
                  }}
                >
           <MdCancel style={{color:activeStep === 3 ? "#BC172F" : "#151515"}} />
                  <Typography sx={{ fontWeight: 300, color:activeStep === 3 ? "#BC172F" : "#151515" }}>Cancelled</Typography>
                </MenuItem>
              </Box>
            </Grid>
            <Grid item lg={9} md={9}>
              <Box
              className="hide_scrollbar"
                sx={{
                  border: "1px solid #dadada",
               
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  bgcolor: "#fff",
                  overflow:'scroll'

                }}
              >
                {activeStep === 0 && <Upcoming />}
                {activeStep === 1 && <Saved />}
                {activeStep === 2 && <Favourites />}
                {activeStep === 3 && <Cancelled />}
              </Box>
            </Grid>
                </>
              )
            }
   
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Reservation;
