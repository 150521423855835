import React from "react";
import Lottie from "react-lottie";
import emptyAnime from "../../Assets/anime/not-found.json";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from '../../Assets/logo/logo.svg'


const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",

        }}
      >
       
       <img src={logo}  width={100} style={{marginBottom:'40px'}}/>
        <Lottie options={defaultOptions} width={400} height={200} />
        <Box align="center">
          <Link to="/">
            <Button variant="contained" sx={{ mt: 4, py:2, borderRadius:'10px', px:3 }}>
        Back to homepage
            </Button>
          </Link>
      
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
