import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider, Button } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Navbar />
      <Box
            className="hide_scrollbar"
        sx={{
          pt: 15,
          px: 4,
          overflow: "scroll",
          width: { lg: "40%", md: "50%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography
          component={"h1"}
          variant="h1"
          sx={{ fontSize: "25px", fontWeight: 700, color: "#151515" }}
        >
          Terms of Service - Reisty for Diners
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          Read the terms and conditions of Reisty.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 3 }}></Box>
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ mt: 2 }}>
            Welcome to Reisty's Restaurant Guest Platform (“Company”, “we”,
            “our”, “us”)! As you have just clicked on our Terms of Service, we
            invite you to take a moment, relax, and carefully read the following
            pages. It should take you approximately 20 minutes. These legal
            documents, along with the Privacy Policy, may contain some
            "legalese," but we've made an effort to make them as easy to
            understand as possible.
            <br />
            <br />
            These Terms of Service (“Terms”, “Terms of Service”) govern your use
            of our web pages and our mobile application Reisty Guest (together
            or individually “Service”) operated by Reisty Technology Limited.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Communications
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mt: 2 }}>
              By using our Service, you consent to receiving electronic
              communications from Reisty. These communications may include
              updates, announcements, and promotional offers. You can opt out of
              receiving marketing communications at any time.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Purchases
          </Typography>
          <Typography sx={{ mt: 2 }}>
            When making purchases through our platform, you agree to provide
            accurate and complete information. You also authorize Reisty to
            charge your selected payment method for the total purchase amount,
            including applicable taxes and fees.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Code of Conduct
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We expect all users to maintain a respectful and professional
            environment on our platform. Any abusive, offensive, or harmful
            behavior is strictly prohibited.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            KYC (Know Your Customer)
          </Typography>
          <Typography sx={{ mt: 2 }}>
            For security purposes, we may require identity verification and
            other information from guests to ensure compliance with regulations.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Fee Changes
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty reserves the right to change fees or introduce new charges.
            We will provide advance notice of such changes.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Currency Conversion
          </Typography>
          <Typography sx={{ mt: 2 }}>
            If currency conversion is necessary for transactions, Reisty will
            apply exchange rates as provided by relevant financial institutions.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Chargeback
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Unauthorized or disputed charges may result in the suspension or
            termination of guest accounts.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Refunds
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Refund policies for purchases are determined by the restaurant
            partners. Reisty is not responsible for refund processing.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Content
          </Typography>
          <Typography sx={{ mt: 2 }}>
            By using our Service, you agree not to post or share content that
            violates any laws or infringes on third-party rights.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Prohibited Uses
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You may not use our Service for any illegal or unauthorized
            purposes, including but not limited to hacking, data mining, or
            transmission of viruses.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Analytics
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty may use analytics tools to gather information about platform
            usage to improve services and user experience.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Accounts
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities under your account.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Intellectual Property and Copyright
          </Typography>
          <Typography sx={{ mt: 2 }}>
            All intellectual property rights and copyrights related to our
            platform remain the property of Reisty.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            DMCA Notice and Procedure for Copyright Infringement Claims
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty complies with the Digital Millennium Copyright Act (DMCA) and
            will respond to valid infringement claims.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Error Reporting and Feedback
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We encourage users to report errors and provide feedback for
            platform improvements.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Links to Other Websites
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty's platform may contain links to external websites. We are not
            responsible for the content or privacy practices of these sites.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Disclaimer of Warranty
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty's platform is provided "as is" without warranties of any
            kind, express or implied.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Governing Law
          </Typography>
          <Typography sx={{ mt: 2 }}>
            These terms are governed by the laws of the Federal Republic of
            Nigeria
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Amendments to Terms
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Reisty reserves the right to modify these terms at any time. Updated
            terms will be posted on our platform.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Waiver and Severability
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Failure to enforce any provision of these terms does not constitute
            a waiver. If any provision is deemed invalid, the remainder of the
            terms remains in effect.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Acknowledgment
          </Typography>
          <Typography sx={{ mt: 2 }}>
            By using our Service, you acknowledge that you have read,
            understood, and agree to these Terms of Service.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Contact Us
          </Typography>
          <Typography sx={{ mt: 2 }}>
            For questions or concerns about these terms, please contact us at{" "}
            <a
              href="mailto:support@reisty.com"
              style={{ textDecoration: "underline" }}
            >
              <b>support@reisty.com</b>
            </a>
            .<br />
            <br />
            Thank you for being responsible and joining us on this culinary
            journey with the Reisty Guest Platform!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Link to="/privacy-policy">
            <Button startIcon={<MdOutlineChevronLeft />}>Privacy Policy</Button>
          </Link>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Terms;
