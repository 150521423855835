import { Box } from '@mui/material'
import React, { useState } from 'react'
import EmptyData from '../../Common/EmptyData'

const Transactions = () => {
  const [data, setData] = useState(null)
  return (
  <>
  <Box sx={{height:'70vh', display:'grid', placeItems:'center'}}>
{
  (!data || data?.length === 0) ? (
    <>
    <EmptyData text=" No transactions available"/>
    </>
  ):(
    <>
    
    </>
  )
}

  
  </Box>
  </>
  )
}

export default Transactions