import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type, canonicalUrl}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
  <link rel="canonical" href={canonicalUrl} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />

{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name} />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
{ /* End Twitter tags */ }

   <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-3FB4WR8J6N"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-3FB4WR8J6N');
        `}
        </script>
</Helmet>
)
}