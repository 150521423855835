import React from 'react'
import doneIcon from '../../../Assets/icons/done.svg';
import {

  Box,
  Button,
  Modal,
  Typography
} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  boxSizing:'border-box',
  borderRadius:'10px',
  p: 4,
};

const DoneModal = ({caption, onClose}) => {
      const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
<>
   <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box  align="center">
<img src={doneIcon}/>
<Typography>{caption}</Typography>
<Button onClick={handleClose} sx={{mt:3, borderRadius:'8px', px:4, py:1}} variant="contained">Close</Button>
          </Box>
        </Box>
      </Modal>
</>
  )
}

export default DoneModal