import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Modal,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  editUserProfile,
  getUserProfile,
  uploadUserImage,
} from "../../../../api";
import Loader from "../../Common/Loader";
import { useSnackbar } from "notistack";
import doneIcon from "../../../../Assets/icons/done.svg";
import { CiSaveUp1 } from "react-icons/ci";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  boxSizing: "border-box",
  borderRadius: "10px",
  p: 4,
};

const Settings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [bio, setBio] = useState("");
  const [dietaryNote, setDietaryNote] = useState("");
  const [email, setEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGetProfile = async () => {
    setIsLoading(true);
    await getUserProfile()
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          setData(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    setFirstName(data?.FirstName);
    setLastName(data?.LastName);
    setPhone(data?.PhoneNumber);
    setAnniversary(data?.Anniversary);
    setEmail(data?.EmailAddress);
    setBirthday(data?.Birthday);
    setDietaryNote(data?.DietaryNote);
    setSelectedFileURL(data?.ProfileImage);
  }, [data]);

  const handleUpdateProfile = async () => {
    // handleChangeProfileImage();
    setIsLoading(true);
    await editUserProfile(
      firstName,
      lastName,
      phoneNumber,
      birthday,
      anniversary,
      bio,
      dietaryNote
    )
      .then((res) => {
   if(res?.data?.status){
         handleGetProfile();
 
        handleOpen();
   }else{
            handleAlert("error", `${res?.data?.error_message}`)
   }
      })
      .catch((err) => {
        // console.log(err);
             handleAlert("error", `${err?.message}`)

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [imageLoading, setImageLoading] = useState(false)

  const handleChangeProfileImage = async () => {
    setImageLoading(true);
    await uploadUserImage(selectedFile)
      .then((res) => {
        // console.log(res);
        if(res?.data?.status){
           handleGetProfile();
        }else{
           handleAlert("error", `${res?.data?.error_message}`)
        }
      })
      .catch((err) => {
        // console.log(err);
              handleAlert("error", `${err?.message}`)
      })
      .finally(() => {
        setImageLoading(false);
      });
  };

  // console.log(selectedFileURL);

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          bgcolor: "#f5f5f5",
          height: "300px",
          borderRadius: "8px 8px 0px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px  solid  #dadada",
        }}
      >
        <Box
          sx={{
            border: "2px  solid #BC172F",
            borderRadius: "50%",
            bgcolor: "#fff",
            boxSizing: "border-box",
            p: 0.3,
          }}
        >
          <Avatar
            src={selectedFileURL}
            sx={{ width: "80px", height: "80px" }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={handleButtonClick}
            sx={{ borderRadius: "10px", px: 2, py: 1 ,         fontSize:isMobile ? "12px" : '13px',}}
            variant="contained"
          >
            Upload New
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <Button
          onClick={handleChangeProfileImage}
          startIcon={<CiSaveUp1 />}
            sx={{
              ml: 3,
              borderRadius: "10px",
              px: 2,
              py: 1,
              bgcolor: "#e8e8e8",
              color: "#151515",
                      fontSize:isMobile ? "12px" : '13px',
                      "&:hover":{
                              bgcolor: "#e8e8e8b7",
                      }
            }}
            variant="contained"
          >
            {
              imageLoading ? <CircularProgress  size={20}/>  : "Save Avatar" 
            }
     
          </Button>
        </Box>
      </Box>
      <Box sx={{}}>
        <Box
          sx={{
            width: { lg: "70%", md: "70%", sm: "90%", xs: "100%" },
            margin: "0 auto",
            py: 4,
          }}
        >
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: {lg:"13px", md:'13px', sm:'12px', xs:'12px'},
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                First Name
              </InputLabel>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: {lg:"13px", md:'13px', sm:'12px', xs:'12px'},
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Last Name
              </InputLabel>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                 fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={0}
              xs={0}
              sx={{
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
              }}
            >
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: {lg:"13px", md:'13px', sm:'12px', xs:'12px'},
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Phone Number
              </InputLabel>
              <TextField
                value={"+234"}
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                     fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                    background: "#ddd",
                  },
                }}
              />
            </Grid>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Phone Number
              </InputLabel>
              <TextField
                value={phoneNumber}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone Number"
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Email Address
              </InputLabel>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address "
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    background: "#ddd",
                    fontWeight: 500,
                  },
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={6}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Birthday
              </InputLabel>
              <TextField
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                margin="dense"
                size="large"
                fullWidth
                type="date"
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={6}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Your Anniversary
              </InputLabel>
              <TextField
                value={anniversary}
                onChange={(e) => setAnniversary(e.target.value)}
                placeholder="Your Location"
                margin="dense"
                size="large"
                fullWidth
                type="date"
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Bio
              </InputLabel>
              <TextField
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="write something about yourself"
                margin="dense"
                multiline
                minRows={4}
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                     fontSize:isMobile ? "12px" : '13px',
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Dietary Note
              </InputLabel>
              <TextField
                value={dietaryNote}
                onChange={(e) => setDietaryNote(e.target.value)}
                placeholder="Dietary Note"
                margin="dense"
                multiline
                minRows={4}
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                       fontSize:isMobile ? "12px" : '13px',
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                 disabled={!phoneNumber || !email || !firstName || !lastName}
                onClick={handleUpdateProfile}
                variant="contained"
                fullWidth
                sx={{ py: 2, borderRadius: "10px" }}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="center">
            <img src={doneIcon} />
            <Typography>Your changes were saved!</Typography>
            <Button
         
              onClick={handleClose}
              sx={{ mt: 3, borderRadius: "8px", px: 4, py: 1 }}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Settings;
