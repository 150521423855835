import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Footer/Footer";
import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import logo from "../../../Assets/logo/logo-icon-red.svg";
import GoogleIcon from "../../../Assets/icons/gooogle.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { loginUser } from "../../../api";
import Loader from "../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleLogin from "../../Components/Auth/GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import queryString from "query-string";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const from = location.state?.from?.pathname || "/";
  const queryParams = queryString.parse(location.search);
  const {
    callBack,
    restaurantName,
    guest,
    time,
    date,
    deposit,
    Email,
  } = queryParams;

  const locationState = location.state;

  useEffect(() => {
    setEmail(Email);
  }, []);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleLogin = async () => {
    setIsLoading(true);
    await loginUser(email, password)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          localStorage.setItem(
            "userInfo",
            JSON.stringify(res?.data?.result[0])
          );
          dispatch(setUser(res?.data?.result[0]));
          navigate(from, { replace: true });
          if (locationState && locationState.redirectTo) {
            navigate(`${locationState?.redirectTo}`);
          } else {
            navigate("/");
          }
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
        // console.log(err);
      });
  };
  return (
    <>
      {/* <Navbar /> */}
      {isLoading && <Loader />}
      <Box
        sx={{
          // background: `url('${watermark}')`,
          height: "100vh",
          backgroundImage:
            "linear-gradient(to right bottom, #fef5f7, #fdf8fa, #fdfafd, #fefdfe, #ffffff)",
        }}
      >
        <Box
          sx={{
            pt: { lg: 15, md: 15, sm: 12, xs: 10 },
            display: "grid",
            placeItems: "center",
            // backdropFilter: "opacity(0.6)",
            // bgcolor:'#ffffff'
          }}
        >
          <Box
            sx={{
              width: { xl: "30%", lg: "35%", md: "30%", sm: "80%", xs: "90%" },
              boxShadow:
                "0 2.8px 2.2px rgba(49, 43, 65, .034), 0 6.7px 5.3px rgba(49, 43, 65, .048), 0 12.5px 10px rgba(49, 43, 65, .06), 0 22.3px 17.9px rgba(49, 43, 65, .072), 0 41.8px 33.4px rgba(49, 43, 65, .086), 0 100px 80px rgba(49, 43, 65, .12)",
              mb: 10,
              borderRadius: "10px",
              py: 6,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              border: "1px solid #e6e7eb",
              bgcolor: "#fff",
            }}
          >
            <Box
              align="center"
              sx={{ width: { lg: "80%", md: "80%", sm: "80%", xs: "90%" } }}
            >
              <Link to="/">
                <img src={logo} alt="reisty_logo" />
              </Link>
              <Typography sx={{ mt: 1, fontSize: "12px", textAlign: "center" }}>
                Your account information and reservations are <br />
                securely managed by Reisty.
              </Typography>
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin />
              </GoogleOAuthProvider>
            </Box>

            <Box
              sx={{
                my: 3,
                width: { lg: "80%", md: "80%", sm: "80%", xs: "90%" },
              }}
            >
              <Divider>or</Divider>
            </Box>
            <Box sx={{ width: { lg: "80%", md: "80%", sm: "80%", xs: "90%" } }}>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "12px",
                  fontFamily: "outfit",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Email
              </InputLabel>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="yourmail@gmail.com"
                margin="dense"
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "12px",
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                  },
                }}
              />
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "12px",
                  fontFamily: "outfit",
                  textAlign: "left",
                  mt: 1,
                  fontWeight: 500,
                }}
              >
                Password*
              </InputLabel>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="**********"
                margin="dense"
                size="large"
                type={showPassword ? "text" : "password"}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "12px",
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                    // Replace with your desired font family
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{ fontSize: "16px" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                // onClick={handleOpen}
                color="primary"
                sx={{
                  textAlign: "left",
                  fontSize: "12px",
                  // color: "primary",
                  cursor: "pointer",
                  fontWeight: 500,
                  "&:hover": {
                    fontFamily: "outfit",
                  },
                }}
              >
                Forgot password?
              </Typography>

              <Button
                disabled={!email || !password}
                fullWidth
                variant="contained"
                sx={{ py: 2, mt: 2, borderRadius: "10px" }}
                onClick={handleLogin}
              >
                Login
              </Button>

              <Box sx={{ mt: 2, pt: 2, borderTop: "1px solid  #d7d7d7" }}>
                <Typography
                  sx={{ fontSize: "10px", textTransform: "uppercase" }}
                >
                  Don't have an account?
                  <Link
                    to="/signup"
                    style={{
                      color: "#BC172F",
                      marginLeft: "3px",
                      textDecoration: "underline",
                      fontWeight: 700,
                    }}
                  >
                    Sign up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
