import { Box, Button, Typography } from "@mui/material";
import React from "react";
import img1 from "../../../Assets/images/claim.png";
import img2 from "../../../Assets/images/claim2.png";

const MiddleHero = () => {
  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          width: {xl:"75%", lg:'85%', sm:'90%', xs:'90%'},
          mt: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'},
                   height: {lg:"400px", md:'400px', sm:'750px', xs:'800px'},
                  //  border:'2px solid red'
        }}
      >
        <Box
          sx={{
            width: {lg:"49%", md:'49%', sm:'100%', xs:'100%'},
            height: {lg:"400px", md:'400px', sm:'auto', xs:'auto'},
            background: `url('${img1}')`,
            backgroundSize: "cover",
            p: 3,
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{ color: "#fff", textTransform: "uppercase", fontWeight: 500,fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}   }}
          >
            Restuarant owners
          </Typography>
          <Typography
               component={"h2"}
                variant="h2"
            sx={{
              color: "#fff",
              textTransform: "uppercase",
              fontWeight: 700,
             fontSize:{lg:'40px', md:'40px', sm:'30px', xs:'25px'} ,
              lineHeight: {lg:"55px", md:'55px', sm:'40px', xs:'32px'},
              mt: 2,
              fontFamily:'optima'
            }}
          >
            Claim your <br />
            restaurant <br />
            today!
          </Typography>
          <Typography sx={{ color: "#fff", mt: 2,fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}   }}>
            Claim your restaurant on Reisty to take control of your online
            presence, manage reservations, menus, and reviews, and showcase your
            establishment to potential diners while maintaining full control."
          </Typography>
          <a href="https://restaurant.reisty.com/get-started" target="_blank">          <Button
            sx={{
              mt: 3,
              bgcolor: "#fff",
              py: 1.5,
              px: 6,
              borderRadius: "50px",
              '&:hover':{
                bgcolor:'#ffffffb7'
              }
            }}
          >
            See full list
          </Button>
          </a>

        </Box>
        <Box
          sx={{
         width: {lg:"49%", md:'49%', sm:'100%', xs:'100%'},
              height: {lg:"400px", md:'400px', sm:'400px', xs:'400px'},
            bgcolor: "#f2f2f2",
            display: "flex",
            boxSizing: "border-box",
            mt:{lg:0, md:0 , sm:2, xs:2}
          }}
        >
          <Box sx={{ height: "100%", width: "60%", p: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}   }}>
              WAITLIST MANAGEMENT
            </Typography>
            <Typography
                 component={"h2"}
                variant="h2"
              sx={{
                fontWeight: 700,
                fontSize:{ lg:"25px", md:'25px', sm:'20px', xs:'18px'},
                lineHeight: {lg:"35px", md:'35px',  sm:'30px', xs:'25px'},
                mt: 2,
                fontFamily:'outfit'
              }}
            >
              RECEIVE AND
              <br /> MANAGE <br />
              RESERVATIONS
              <br /> ONLINE WITH
              <br /> REISTY
            </Typography>
            <Typography sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}  }}>
              We whisk away the stress of managing reservations and tables,
              allowing you to orchestrate your dining experience effortlessly
              and indulge your guests with satisfaction.
            </Typography>

            <Button
              variant="contained"
              sx={{ mt: 2, py: 1.5, borderRadius: "50px" }}
            >
              Create a free account
            </Button>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "40%",
              background: `url('${img2}')`,
              backgroundSize: "cover",
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
};

export default MiddleHero;
