import React from "react";
import img1 from "../../../Assets/images/Discover/1.png";
import img2 from "../../../Assets/images/Discover/2.png";
import img3 from "../../../Assets/images/Discover/3.png";
import img4 from "../../../Assets/images/Discover/4.png";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Discover = () => {
  return (
    <>
      <Box
      className="hide_scrollbar"
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          mt: 8,

          height:'350px'
        }}
      >
        <Box>
          <Typography
               component={"h3"}
                variant="h3"
            sx={{
              fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
              fontWeight: 500,
            }}
          >
            Discover our finest restaurants
          </Typography>
        </Box>
        <Box
           className="hide_scrollbar"
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "scroll",
            columnGap: 2,
          }}
        >
          <Link  to={`/restaurant/find?Text=fine dining`}>
          <Box sx={{}}>
            <Box
              sx={{
                height: "260px",
                background: `url('${img1}')`,
                width: "240px",
                backgroundSize: "cover",
              }}
            ></Box>
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Fine Dining
            </Typography>
          </Box>
          </Link>

           <Link  to={`/restaurant/find?Text=buffet`}>
          <Box sx={{}}>
            <Box
              sx={{
                height: "260px",
                background: `url('${img2}')`,
                width: "240px",
                backgroundSize: "cover",
              }}
            ></Box>
            <Typography
              sx={{
                mt: 1,
                ontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Buffet
            </Typography>
          </Box>
          </Link>

             <Link  to={`/restaurant/find?Text=family`}>
          <Box sx={{}}>
            <Box
              sx={{
                height: "260px",
                background: `url('${img3}')`,
                width: "240px",
                backgroundSize: "cover",
              }}
            ></Box>
            <Typography
              sx={{
                mt: 1,
                ontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Family
            </Typography>
          </Box>
          </Link>
               <Link  to={`/restaurant/find?Text=cafe`}>
          <Box sx={{}}>
            <Box
              sx={{
                height: "260px",
                background: `url('${img4}')`,
                width: "240px",
                backgroundSize: "cover",
              }}
            ></Box>
            <Typography
              sx={{
                mt: 1,
                ontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Cafes
            </Typography>
          </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Discover;
