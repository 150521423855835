import all from '../icons/amenities/all.svg';
import ambience from '../icons/amenities/ambience.svg';
import american from '../icons/amenities/american.svg';
import bar from '../icons/amenities/bar.svg';
import bbq from '../icons/amenities/bbq.svg';
import bistro from '../icons/amenities/bistro.svg';
import breakfast from '../icons/amenities/breakfast.svg';
import brunch from '../icons/amenities/brunch.svg';
import burger from '../icons/amenities/burger.svg';
import cafe from '../icons/amenities/cafe.svg';
import chicken from '../icons/amenities/chicken.svg';
import child_friendly from '../icons/amenities/child_friendly.svg';
import chinese from '../icons/amenities/chinese.svg';
import cocktail from '../icons/amenities/cocktail.svg';
import desert from '../icons/amenities/desert.svg';
import great_views from '../icons/amenities/great_views.svg';
import indian from '../icons/amenities/indian.svg';
import italian from '../icons/amenities/italian.svg';
import japanese from '../icons/amenities/japanese.svg';
import korean from '../icons/amenities/korean.svg';
import local from '../icons/amenities/local.svg';
import mexican from '../icons/amenities/mexican.svg';
import newIcon from '../icons/amenities/new.svg';

import ocean_views from '../icons/amenities/ocean_views.svg';
import outdoor from '../icons/amenities/outdoor.svg';
import pet_friendly from '../icons/amenities/pet_friendly.svg';
import pizza from '../icons/amenities/pizza.svg';
import playground from '../icons/amenities/playground.svg';
import pool_side from '../icons/amenities/pool_side.svg';

import privateIcon from '../icons/amenities/private.svg';
import rooftops from '../icons/amenities/rooftops.svg';
import seafood from '../icons/amenities/seafood.svg';
import sushi from '../icons/amenities/sushi.svg';
import tacos from '../icons/amenities/tacos.svg';
import tea from '../icons/amenities/tea.svg';
import thai from '../icons/amenities/thai.svg';
import trending from '../icons/amenities/trending.svg';
import vegan from '../icons/amenities/vegan.svg';
import wine from '../icons/amenities/wine.svg';

// ACTIVE....

import activeAll from '../icons/amenities/active/all.svg';
import activeAmbience from '../icons/amenities/active/ambience.svg';
import activeAmerican from '../icons/amenities/active/american.svg';
import activeBar from '../icons/amenities/active/bar.svg';
import activeBbq from '../icons/amenities/active/bbq.svg';
import activeBistro from '../icons/amenities/active/bistro.svg';
import activeBreakfast from '../icons/amenities/active/breakfast.svg';
import activeBrunch from '../icons/amenities/active/brunch.svg';
import activeBurger from '../icons/amenities/active/burger.svg';
import activeCafe from '../icons/amenities/active/cafe.svg';
import activeChicken from '../icons/amenities/active/chicken.svg';
import activeChildFriendly from '../icons/amenities/active/child_friendly.svg';
import activeChinese from '../icons/amenities/active/chinese.svg';
import activeCocktail from '../icons/amenities/active/cocktail.svg';
import activeDesert from '../icons/amenities/active/desert.svg';
import activeGreatViews from '../icons/amenities/active/great_views.svg';
import activeIndian from '../icons/amenities/active/indian.svg';
import activeItalian from '../icons/amenities/active/italian.svg';
import activeJapanese from '../icons/amenities/active/japanese.svg';
import activeKorean from '../icons/amenities/active/korean.svg';
import activeLocal from '../icons/amenities/active/local.svg';
import activeMexican from '../icons/amenities/active/mexican.svg';
import activeNewIcon from '../icons/amenities/active/new.svg';
import activeOceanViews from '../icons/amenities/active/ocean_views.svg';
import activeOutdoor from '../icons/amenities/active/outdoor.svg';
import activePetFriendly from '../icons/amenities/active/pet_friendly.svg';
import activePizza from '../icons/amenities/active/pizza.svg';
import activePlayground from '../icons/amenities/active/playground.svg';
import activePoolSide from '../icons/amenities/active/pool_side.svg';
import activePrivateIcon from '../icons/amenities/active/private.svg';
import activeRooftops from '../icons/amenities/active/rooftops.svg';
import activeSeafood from '../icons/amenities/active/seafood.svg';
import activeSushi from '../icons/amenities/active/sushi.svg';
import activeTacos from '../icons/amenities/active/tacos.svg';
import activeTea from '../icons/amenities/active/tea.svg';
import activeThai from '../icons/amenities/active/thai.svg';
import activeTrending from '../icons/amenities/active/trending.svg';
import activeVegan from '../icons/amenities/active/vegan.svg';
import activeWine from '../icons/amenities/active/wine.svg';




const icons = [
  all, ambience, american, bar, bbq, bistro, breakfast, brunch, burger, cafe,
  chicken, child_friendly, chinese, cocktail, desert, great_views, indian, italian, japanese, korean,
  local, mexican, newIcon, ocean_views, outdoor, pet_friendly, pizza, playground, pool_side,
 privateIcon, rooftops, seafood, sushi, tacos, tea, thai, trending, vegan, wine
];

const icons2 = [
  activeAll, activeAmbience, activeAmerican, activeBar, activeBbq, activeBistro, activeBreakfast, activeBrunch, activeBurger, activeCafe,
  activeChicken, activeChildFriendly, activeChinese, activeCocktail, activeDesert, activeGreatViews, activeIndian, activeItalian, activeJapanese, activeKorean,
  activeLocal, activeMexican, activeNewIcon, activeOceanViews, activeOutdoor, activePetFriendly, activePizza, activePlayground, activePoolSide,
  activePrivateIcon, activeRooftops, activeSeafood, activeSushi, activeTacos, activeTea, activeThai, activeTrending, activeVegan, activeWine
];

const titles = [
  "All", "Ambience", "American", "bar", "bbq", "bistro", "breakfast", "brunch", "burger", "cafe",
  "chicken", "Child friendly", "chinese", "cocktail", "desert", "great views", "indian", "italian", "japanese", "korean",
  "local", "mexican", "ocean views", "outdoor", "pet friendly", "pizza", "playground", "pool side",
 , "private", "rooftops", "seafood", "sushi", "tacos", "tea", "thai", "trending", "vegan", "wine"
];





const Amenities =icons.map((icon, index) => ({
  title: titles[index],
  icon: icon,
  icon2:icons2[index]
}));

export default Amenities