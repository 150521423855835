import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import logo from "../../../Assets/logo/logo-icon-red.svg";

import { loginUser, resendOtp, verifiyOtp } from "../../../api";
import Loader from "../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/userSlice";
import { useNavigate, useParams } from "react-router-dom";

import { useSnackbar } from "notistack";

const Verify = () => {
  const { email } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [otp, setOtp] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleLogin = async () => {
    setIsLoading(true);
    await verifiyOtp( otp, email)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          navigate(`/login?Email=${email}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
        // console.log(err);
      });
  };

  const handleResendCode = async () => {
    setIsLoading(true);

    await resendOtp(email)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        // console.log(err);
        handleAlert("error", `${err?.message}`);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Navbar />
      {isLoading && <Loader />}
      <Box sx={{ mt: 20, display: "grid", placeItems: "center" }}>
        <Box
          sx={{
            width: "40%",
            boxShadow:
              " 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -4px rgba(0,0,0,.1)",
            mb: 10,
            borderRadius: "10px",
            py: 6,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={logo} />
          <Typography
            sx={{
              mt: 1,
              fontSize: "20px",
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            Verify Email
          </Typography>
          <Typography sx={{ mt: 1, fontSize: "14px", textAlign: "center" }}>
            Please, enter the one-time pass code sent to your email.
          </Typography>

          <Box sx={{ my: 5, width: "70%" }}></Box>
          <Box sx={{ width: "70%" }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontFamily: "outfit",
                textAlign: "left",
                fontWeight: 500,
              }}
            >
              One-Time Password
            </InputLabel>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="otp"
              margin="dense"
              size="large"
              type="number"
              fullWidth
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  borderRadius: "10px",
                  offset: " 1px solid #737373",
                  fontWeight: 500,
                },
              }}
            />

            <Typography
              onClick={handleResendCode}
              color="primary"
              sx={{
                textAlign: "left",
                fontSize: "12px",
                // color: "primary",
                cursor: "pointer",
                fontWeight: 500,
                "&:hover": {
                  fontFamily: "outfit",
                  fontWeight:500,
                  textDecoration:'underline'
                },
              }}
            >
              Resend one-time pass code
            </Typography>

            <Button
              disabled={!otp}
              fullWidth
              variant="contained"
              sx={{ py: 2, mt: 2, borderRadius: "10px" }}
              onClick={handleLogin}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Verify;
