import {
  Box,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Search from "./Search";
import searchIcon from "../../../../Assets/icons/search.svg";
import Location from "./Location";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import GuestSize from "./GuestSize";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import anime from "../../../../Assets/anime/location.json";

const FullSearchBar = () => {
  const [text, setText] = useState("");
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);
  const [ReservationTime, setReservationTime] = useState("");
  const [ReservationDay, setReservationDay] = useState("");
  const [partySize, setPartySize] = useState("");
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUpdateText = (newValue) => {
    setText(newValue);
  };
  const handleUpdateAddress = (long, lat) => {
    setLong(long);
    setLat(lat);
  };
  const handleUpdateDate = (newValue) => {
    setReservationDay(newValue);
  };
  const handleUpdateTime = (newValue) => {
    setReservationTime(newValue);
  };
  const handleUpdatePartySize = (newValue) => {
    setPartySize(newValue);
  };

  const handleSearch = () => {
    navigate(
      `/restaurant/find?Text=${text}&Date=${ReservationDay}&Time=${ReservationTime}&Lat=${lat}&Long=${long}&PartySize=${partySize}`
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isMobile ? (
        <Box
          onClick={handleSearch}
          sx={{
            bgcolor: "#fff",
            width: "60px",
            height: "60px",
            margin: "0 auto",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            overflow: "hidden",
            "&:hover": {
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
              transition: "0.4s all ease-in-out",
            },
          }}
        >
          <Lottie options={defaultOptions} width={150} height={150} />
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "#fff",
            height: "65px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
            overflow: "hidden",
            "&:hover": {
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
              transition: "0.4s all ease-in-out",
            },
          }}
        >
          <Box
            sx={{
              width: "22%",
              pr: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Search handleUpdateText={handleUpdateText} />
          </Box>
          <Box
            sx={{
              width: "8%",
              border: "1px solid #dadada",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Location handleUpdateAddress={handleUpdateAddress} />
          </Box>
          <Box
            sx={{
              width: "20%",
              borderRight: "1px solid #dadada",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <DatePicker handleUpdateDate={handleUpdateDate} />
          </Box>
          <Box
            sx={{
              width: "20%",
              borderRight: "1px solid #dadada",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <TimePicker handleUpdateTime={handleUpdateTime} />
          </Box>
          <Box sx={{ width: "25%", display: "grid", placeItems: "center" }}>
            <GuestSize handleUpdatePartySize={handleUpdatePartySize} />
          </Box>
          <Box sx={{ width: "5%" }}>
            <IconButton onClick={handleSearch}>
              <img src={searchIcon} width={100} />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FullSearchBar;
