import React, { useCallback, useMemo } from 'react';
import { PaystackButton } from 'react-paystack';

const PaystackPaymentButton = ({ first_name, last_name, email, amount, onSuccessAction, label }) => {
  const config = useMemo(() => ({
    reference: `${Date.now()}`,
    email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_CODE,
    // publicKey: "pk_test_6c0b3d295bf7fd5d570b36fdfde7a6c5efcc9a73",
    firstName: first_name,
    lastName: last_name,
  }), [email, amount, first_name, last_name]);

  const handlePaystackSuccessAction = useCallback((reference) => {
    if (onSuccessAction && typeof onSuccessAction === 'function') {
      onSuccessAction(reference?.reference);
    }
    // console.log('Payment successful:', reference);
  }, [onSuccessAction]);

  const handlePaystackCloseAction = useCallback(() => {
    // console.log('Paystack dialog closed');
    // Add additional actions if necessary
  }, []);

  const componentProps = useMemo(() => ({
    ...config,
    text: label,
    className: (amount && email) ? "paystack-button" : "paystack-button-disabled",
    onSuccess: handlePaystackSuccessAction,
    onClose: handlePaystackCloseAction,
  }), [config, label, amount, handlePaystackSuccessAction, handlePaystackCloseAction]);

  return <PaystackButton {...componentProps} disabled={!amount || !email} />;
};

export default PaystackPaymentButton;
