import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider, Button } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const Cookies = () => {
  return (
    <>
      <Navbar />
      <Box
        className="hide_scrollbar"
        sx={{
          pt: 15,
          px: 4,
          overflow: "scroll",
          width: { lg: "50%", md: "50%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography
          component={"h1"}
          variant="h1"
          sx={{ fontSize: "25px", fontWeight: 700, color: "#151515" }}
        >
          Cookie Policy
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          This is the cookie policy for Resity.
        </Typography>
        {/* <Divider sx={{ my: 2 }} /> */}

        {/* <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "14px" }}>
            Effective Date: November 15, 2023
          </Typography>
        </Box> */}
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Introduction
          </Typography>
          <Typography sx={{ mt: 2 }}>
            When you browse our website, we use cookies to ensure a seamless
            user experience across all the functions you access. The contents of
            this page are meant to explain to you in clear terms what cookies
            mean and how we use them on our site.
            <br />
            <br />
            Cookies are necessary for our website to perform its functions
            normally. We use them in different contexts, as some are meant to
            manage your session and ensure that you transition smoothly between
            pages when logged in with your browser kept active while others are
            more persistent and linger on your device for a longer period.
            <br />
            <br />
            We give you the option to opt out of cookies in order to let you
            control your privacy. However, we do recommend that for a good user
            experience on our website, you allow Reisty to store cookies.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,

              pb: 1,
            }}
          >
            What are Cookies
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography>
              Reisty uses cookies- tiny files downloaded to your computer to
              improve your experience. Cookies allow a website to recognize a
              particular device or browser. There are several types of cookies:
            </Typography>
            <ul>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Session cookies expire at the end of your browser session and
                  allow us to link your actions during that particular browser
                  session.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Persistent cookies are stored on your device in between
                  browser sessions, allowing us to remember your preferences or
                  actions across multiple sites.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  First-party cookies are set by the site you are visiting.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Third-party cookies are set by a third-party site separate
                  from the site you are visiting.
                </Typography>
              </li>
            </ul>
            <Typography>
              This page describes what information cookies gather, how we use it
              and why we sometimes need to store these cookies. We will also
              share how you can prevent these cookies from being stored. Note
              however that disabling cookies and preventing their storage may
              downgrade or 'break' certain elements of Reisty’s functionality,
              possibly compromising the seamless user experience we seek to
              offer you through the use of these cookies.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            How We Use Cookies
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We use cookies for a variety of reasons (which are detailed below).
            Unfortunately, in most cases, there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to our site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not,
            just in case they are being used to provide a service that you need.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Turn Off or Opt-Out of Cookies
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You can manually disable cookies on your computer and devices, or
            delete existing cookies. Disabling cookies (See How to Disable
            Cookies in Your Browser) may restrict your browsing experience on
            Reisty related to important features such as logging in to your
            profile, navigating webpages etc. Reisty does not share cookie
            information with any other website nor do we sell this data to any
            third party without your consent.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            What We Use Cookies For
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We use cookies to optimize your user experience when you browse our
            website. If you register a profile with us, we will use cookies to
            manage the signup process and general administration and manage your
            browser session while you are logged in. These cookies will usually
            be deleted when you log out. They may however in some cases remain
            afterwards to remember your site preferences when you are logged
            out. <br /> ...
            <br />
            Hopefully, this has clarified things for you. As was previously
            mentioned, if there is something you aren't sure you need, it is
            usually safer to leave cookies enabled in case it does interact with
            one of the features you use on our site. Please feel free to contact
            us if you have any questions. (Insert the DPO contact details)
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            How to Disable Cookies on Your Browser
          </Typography>
          <Typography sx={{ mt: 2, mb: 5 }}>
            You can disable your browser cookies if you follow the steps below
            for Internet Explorer, Microsoft Edge, Google Chrome, Mozilla
            Firefox and Opera browsers.
            <br />
            <br />
            Disable Cookies in Internet Explorer
            <ul>
              <li>Open Internet Explorer and click the Tools button.</li>
              <li>Next click Internet Options and select the Privacy tab.</li>
              <li>
                Under Settings, move the slider to the top to block all cookies
                or to the bottom to allow all cookies, and then click Apply.
              </li>
              <li>
                As you move the slider up or down, you will see a description of
                the types of cookies that will be blocked or allowed by Internet
                Explorer at that privacy level.
              </li>
            </ul>
            Starting from the top you will see the following options:
            <ul>
              <li>
                Block all Cookies from all websites. Block all Cookies from
                websites that do not have a compact privacy policy
              </li>
              <li>
                Block all cookies from websites that do not have a compact
                privacy policy. Block cookies that save information that can be
                used to contact you without your explicit consent
              </li>
              <li>
                Block third-party cookies that do not have a compact privacy
                policy. Block third-party cookies that save information that can
                be used to contact you without your explicit consent. Blocks
                firstly-partly cookies that save information that can be used to
                contact you without your implicit consent
              </li>
              <li>
                Block third-party cookies that do not have a compact privacy
                policy. Block third-party cookies that save information that can
                be used to contact you without your explicit consent. Restricts
                firstly-partly cookies that save information that can be used to
                contact you without your implicit consent. This is the default
                setting.
              </li>
              <li>
                Block third-party cookies that do not have a compact privacy
                policy. Restricts third-party cookies that save information that
                can be used to contact you without your implicit consent
              </li>
              <li>
                Saves cookies from any website. Cookies that are already on this
                computer can be read by the websites that created them
              </li>
            </ul>
            Select the level of security you want. For most, the Default option
            is the best.
            <br />
            <em>Disable Cookies in Microsoft Edge</em>
            <ul>
              <li>Open Edge browser</li>
              <li>Click on the 3-dotted 'More' link to open its Settings</li>
              <li>Scroll down and click on View Advanced Settings</li>
              <li>Again, scroll down till you see the setting for Cookies</li>
              <li>When here, from the 3 options, select one</li>
            </ul>
            The available options are:
            <ul>
              <li>Block all Cookies</li>
              <li>Block only third-party cookies</li>
              <li>Don't block cookies</li>
            </ul>
            To disable all Cookies, ensure that block all cookies are selected,
            and restart Microsoft Edge.
            <br />
            <em>Disable Cookies in Chrome</em>
            <ul>
              <li>
                To disable Cookies on Chrome, click the Tools button &gt;
                Settings. Towards the end of this page, you will see the Show
                Advanced Settings link
              </li>
              <li>Click on it. Under Priva</li>
              <li>
                Click on the Content Settings button. Here you can select the
                options you feel are best suited for you
              </li>
              <li>You can click on the images to see the larger versions</li>
            </ul>
            Disable Cookies in Firefox
            <ul>
              <li>In Firefox, open Settings &gt; Options &gt; Privacy tab</li>
              <li>
                Under History, from the drop-down menu, select Use custom
                settings for history
              </li>
              <li>You can now select the Cookies options you want</li>
            </ul>
            Disable Cookies in Opera
            <ul>
              <li>Open settings &gt; Privacy & Security</li>
              <li>
                Under Cookies you will see the options to manage your Cookie
                settings and choose which kind of Cookies you wish to allow or
                disallow on your computer
              </li>
            </ul>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Cookies;
