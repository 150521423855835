import React from "react";
import Navbar from "../Components/Common/Navbar";
import Banner from "../Components/Banner/Banner";
import Featured from "../Components/Home/Featured";
import MiddleHero from "../Components/Home/MiddleHero";
import Best from "../Components/Home/Best";
import Blog from "../Components/Home/Blog";
import FooterHero from "../Components/Footer/FooterHero";
import Footer from "../Components/Footer/Footer";
import Discover from "../Components/Home/Discover";
import Popular from "../Components/Home/Popular";
import { motion } from "framer-motion";
import { Typography,Modal } from "@mui/material";
import Box from "@mui/material/Box";
import SEO from "../../utils/SEO";

const Home = () => {
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };



  return (
    <>
      <SEO
        title="Reisty | Discover and Book Top Restaurants in Lagos - Instantly"
        description="Explore the best dining experiences Lagos offers with Reisty! From trendy cafes to exquisite fine dining, find menus, read reviews, and book your table online today."
        name="Reisty"
        type="article"
        canonicalUrl="https://reisty.com"
      />
      <motion.div
        variants={fadeInOutVariants}
        initial="initial"
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        animate="animate"
        exit="exit"
      >
        <Navbar />
        <Banner />

        <Featured />
        <Discover />
        <Popular />
        <MiddleHero />
        <Best />
        <Blog />
        <FooterHero />
        <Footer />
      </motion.div>


    </>
  );
};

export default Home;


