import { InputLabel, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { alpha, styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({

  color: "#fff",
  fontWeight: 500,
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "transparent",
    color: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "#fff",
      fontWeight: 500,
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "#fff",
      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "#fff",
      fontWeight: 500,
    },
  },
});

const Search = ({handleUpdateText}) => {
  const [value, setValue] = useState("")

  const handleChange = (e) =>{
    setValue(e.target.value)

  }
  useEffect(()=>{
    handleUpdateText(value)
  },[value])
  return (
<>
<InputLabel sx={{color:'#717171', fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}, mb:-1}}>Search</InputLabel>
<CssTextField
value={value}
onChange={handleChange}
sx={{ml:-2, }}
fullWidth
size='small'
placeholder='Restaurants, cuisines...'  InputProps={{
  style:{
    border:'none',
    p:0,
    color:'#000',
    fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}

  }
}}/>
</>
  )
}

export default Search