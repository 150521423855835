import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import RestaurantCard from "../Common/RestaurantCard";
import { getAllFeaturedRestaurants } from "../../../api";
import Loader from "../Common/Loader";
import { Link } from "react-router-dom";

const Popular = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleGetFeatured = async () => {
    setIsLoading(true);
    await getAllFeaturedRestaurants()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetFeatured();
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          mt: 6,
          height: { lg: "330px", md: "330px", sm: "520px", xs: "460px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              component={"h3"}
              variant="h3"
              sx={{
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontWeight: 500,
              }}
            >
              Popular places
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
              }}
            >
              Discover the best restaurants in your local area
            </Typography>
          </Box>
          <Box>
            {/* <Button endIcon={<MdArrowRightAlt />} sx={{fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'10px'}}}>See More </Button> */}
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          {isLoading ? (
            <Grid container spacing={3}>
              {[1, 2, 3, 4]?.map((restaurant, index) => (
                <Grid item lg={3} md={3} sm={4} xs={6} key={index}>
                  <RestaurantCard loading />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={isMobile ? 1 : 3}>
              {data?.slice(0, 4)?.map((restaurant, index) => (
                <Grid item lg={3} md={3} sm={4} xs={6} key={index}>
                  <Link to={`/restaurant/${restaurant?.RestaurantName}`}>
                    <RestaurantCard restaurant={restaurant} noRatings={true} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Popular;
