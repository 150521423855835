import { Box, IconButton, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import { MdArrowDropDown } from "react-icons/md";
import Popover from "@mui/material/Popover";
import Calender from '../../Common/Calendar';

const DatePicker = ({handleUpdateDate}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


    const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];

  const [date, setDate] = useState(toDate);


  useEffect(()=>{
handleUpdateDate(date)
  },[date])

  return (
<>
<Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'80%', cursor:'pointer'}} 
      aria-describedby={id}
        variant="contained"
        onClick={handleClick}
>
<Box>
    <Typography sx={{color:'#717171',     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>Date</Typography>
    <Typography sx={{color:'#000', fontWeight:500,     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>{formatDate(date)}</Typography>
</Box>

<IconButton>
<MdArrowDropDown />
</IconButton>
</Box>

     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
     <Box>
      <Calender 
      past={true}
             onChildValue={(value) => {
              setDate(value);
              setAnchorEl(null);
              // console.log(date);
            }}

      />
     </Box>
      </Popover>
</>
  )
}

export default DatePicker



function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}