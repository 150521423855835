import React, { useEffect, useState } from "react";
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa6";
import bgImg from "../../Assets/images/res1.avif";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  ListItemButton,
} from "@mui/material";
import CustomInput from "../../Main/Components/Custom/CustomInput";
import { getOpeningHours } from "../../api";
import Loader from "../../Main/Components/Common/Loader";
import Information from "./Information";
import { Helmet } from "react-helmet-async";

const ReservationDetails = ({ action, data, user }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [guest, setGuest] = useState(1);
  const [date, setDate] = useState("");
  const [openingHours, setOpeningHours] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setGuest(data?.GuestNum);
    setDate(data?.Date);
  }, [data]);

  const handleGetTime = async () => {
    setIsLoading(true);
    await getOpeningHours(data?.RestaurantDetails?.RestaurantId, date, guest)
      .then((res) => {
        setIsLoading(false);
        // console.log(res?.data?.result);
        setOpeningHours(res?.data?.result[0]?.GroupedTime);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetTime();
  }, [data?.RestaurantDetails?.RestaurantId, date]);
  return (
    <>
        <Helmet>
       <meta name="robots" content="noindex, nofollow" />
      
    </Helmet>
      {isLoading && <Loader />}
      <Box
        sx={{
          height: "200px",
          background: `url('${data?.RestaurantDetails?.ImageUrl}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Box>

  {
    activeStep === 0 && (
      <>
    <Box sx={{ p: 3 }}>
        <Box
          sx={{
            border: "1px solid #d7d7d7",
            p: 1,
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <InputLabel sx={{ fontSize: "10px" }}>Guest</InputLabel>
            <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
              {guest} Guest
            </Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                if (guest > 1) {
                  setGuest((prev) => prev - 1);
                }
              }}
              sx={{ borderRadius: "8px ", border: "1px solid #d7d7d7" }}
            >
              <FaMinus style={{ fontSize: "17px" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setGuest((prev) => prev + 1);
              }}
              sx={{
                borderRadius: "8px ",
                border: "1px solid #d7d7d7",
                ml: 2,
              }}
            >
              <TiPlus style={{ fontSize: "17px" }} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputLabel sx={{ fontSize: "12px" }}>Date</InputLabel>
          <CustomInput
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputLabel sx={{ fontSize: "12px" }}>Time</InputLabel>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            {!openingHours ||
            openingHours.length === 0 ||
            (openingHours[0].length === 0 && openingHours[1]?.length === 0) ? (
              <>
                <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 600,
                      color: "#BC172F",
                    }}
                  >
                    No Available Time for the selected date
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 300,
                      fontSize: "12px",
                    }}
                  >
                    You can try selecting another date
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {openingHours &&
                  openingHours[0]?.map((time, index) => {
                    return (
                      <Grid item lg={3} md={3} sm={4} xs={4} key={index}>
                        <ListItemButton
                          onClick={() => {
                            setSelectedTime(time?.Time);
                          }}
                          selected={selectedTime === time?.Time}
                          sx={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "8px",
                    
                            "&.Mui-selected":{
                              border:'1px solid #BC172F',
                              color:'#BC172F'
                            }
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", whiteSpace: "nowrap", textAlign:'center' }}
                          >
                            {" "}
                            {time?.Time}
                          </Typography>
                        </ListItemButton>
                      </Grid>
                    );
                  })}
                {openingHours &&
                  openingHours[1]?.map((time, index) => {
                    return (
                      <Grid item lg={3} md={3} sm={4} xs={4} key={index}>
                        <ListItemButton
                          onClick={() => {
                            setSelectedTime(time?.Time);
                          }}
                          selected={selectedTime === time?.Time}
                          sx={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "8px",
                                "&.Mui-selected":{
                              border:'1px solid #BC172F',
                              color:'#BC172F'
                            }
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", whiteSpace: "nowrap" }}
                          >
                            {" "}
                            {time?.Time}
                          </Typography>
                        </ListItemButton>
                      </Grid>
                    );
                  })}
              </>
            )}
          </Grid>
        </Box>

        <Button
        disabled={!selectedTime || !guest || !date}
          onClick={() => setActiveStep(1)}
          variant="contained"
          fullWidth
          sx={{ mt: 5, borderRadius: "50px", py: 1.5 }}
        >
          Proceed
        </Button>
      </Box>
      </>
    )
  }
       {
              activeStep === 1 && (
                <>
                
           <Information action={()=>setActiveStep(1)} data={data} guest={guest} date={date} time={selectedTime} user={user}/>
                </>
              )
            }
    </>
  );
};

export default ReservationDetails;
