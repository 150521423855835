import {
  Box,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneModal from "../../Common/DoneModal";
import Loader from "../../Common/Loader";
import { changePassword } from "../../../../api";
import { useSnackbar } from "notistack";

const Passwords = () => {
    const { enqueueSnackbar } = useSnackbar() 
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSuccessful, setIsSuccessfull] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

      const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    setIsSuccessfull(false)

    await changePassword(currentPassword, newPassword, confirmPassword)
    .then((res)=>{
      // console.log(res)
            setIsLoading(false)
      if(res?.data?.status){
        setIsSuccessfull(true)
      }
      else{
            handleAlert("error", `${res?.data?.result}`)
      }
    }).catch((err)=>{
      // console.log(err)
      setIsLoading(false)
      handleAlert("error", `${err?.message}`)

    })
  };
  return (
    <>
      {isSuccessful && <DoneModal caption={"Password change successfully"} />}
      {isLoading && <Loader />}
      <Box
        sx={{ width: {lg:"70%", md:'70%', sm:'85%', xs:'100%'}, margin: "0 auto", boxSizing: "border-box", py: 5 }}
      >
        <Box sx={{ mb: 1 }}>
          <CustomLabel label="Current Password*" />
          <TextField
          value={currentPassword}
          onChange={(e)=>setCurrentPassword(e.target.value)}
            margin="dense"
            placeholder="Hello123@"
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              style: {
                fontFamily: "outfit",
                fontSize: "13px",
                borderRadius: "10px",
                offset: " 1px solid #737373",
                fontWeight: 500,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <CustomLabel label="New Password  *" />
          <TextField
               value={newPassword}
          onChange={(e)=>setNewPassword(e.target.value)}
            margin="dense"
            placeholder="Hello123@"
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              style: {
                fontFamily: "outfit",
                fontSize: "13px",
                borderRadius: "10px",
                offset: " 1px solid #737373",
                fontWeight: 500,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <CustomLabel label="Confirm Password  *" />
          <TextField
               value={confirmPassword}
          onChange={(e)=>setConfirmPassword(e.target.value)}
            margin="dense"
            placeholder="Hello123@"
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              style: {
                fontFamily: "outfit",
                fontSize: "13px",
                borderRadius: "10px",
                offset: " 1px solid #737373",
                fontWeight: 500,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Button
          disabled={!currentPassword || !newPassword  || confirmPassword}
          onClick={handleChangePassword}
            variant="contained"
            sx={{ borderRadius: "8px", px: 4, py: 2 }}
            fullWidth
          >
            Save Password
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Passwords;
