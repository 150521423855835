import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

const OpenTag = ({hoursString }) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const splitBusinessHours = (hoursString) => {

    const [openingTime, closingTime] = hoursString?.split(" till ");
    return { openingTime, closingTime };
  };

  const checkIfOpen = () => {
    const { openingTime, closingTime } = splitBusinessHours(hoursString);
    const now = new Date();

    const parseTime = (timeString) => {
      const [time, modifier] = timeString?.split(' ');
      let [hours, minutes] = time?.split(':');
      hours = parseInt(hours, 10);
      if (modifier === 'PM' && hours < 12) {
        hours += 12;
      } else if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }
      return { hours, minutes: parseInt(minutes, 10) };
    };

    const openTimeObj = parseTime(openingTime);
    const closeTimeObj = parseTime(closingTime);

    const openTime = new Date();
    openTime.setHours(openTimeObj?.hours, openTimeObj?.minutes, 0, 0);

    const closeTime = new Date();
    closeTime.setHours(closeTimeObj?.hours, closeTimeObj?.minutes, 0, 0);

    if (now >= openTime && now <= closeTime) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if(hoursString){

      checkIfOpen();
    }

    // Update state periodically to handle dynamic changes
    const intervalId = setInterval(checkIfOpen, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [hoursString]);

  return (
    <div>
      {
       isOpen  ? (
 <Box sx={{color:'#007e23', bgcolor:'#e7ffe8', px:1,py:0.2, borderRadius:'50px',border:'2px solid #007e23', width:'fit-content'}}>
<Typography sx={{fontSize:'10px', fontWeight:500}}>Opened</Typography>
 </Box>
        ):(
           <Box sx={{color:'#A71200', bgcolor:'#FFE8E5', px:1,py:0.1, borderRadius:'50px',border:'2px solid #A71200', width:'fit-content'}}>
<Typography sx={{fontSize:'7px', fontWeight:500}}>Closed</Typography>
 </Box>
        )
      }

    </div>
  );
};

export default OpenTag;
