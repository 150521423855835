import React ,{useState, useEffect} from "react";
import img1 from "../../../Assets/images/res1.avif";
import {
  Box,
  IconButton,
  InputLabel,
  Rating,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import fav1 from "../../../Assets/icons/fav1.png";
import fav2 from "../../../Assets/icons/fav2.svg";
import OpenTag from "./OpenTag";
import Carousel from "./Carousel";
import defaultImg from '../../../Assets/images/default.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RestaurantCard = ({ restaurant, loading, noRatings }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = restaurant?.Photos

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images?.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images?.length, 8000]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const handleImageError = (event) => {
    event.target.src = defaultImg;
  };
  return (
    <>
      {loading ? (
        <Box
   
          sx={{
            height: {lg:"180px", md:'180px', sm:'180px', xs:'180px'},
            border: "1px solid #dedede",
            transition: "0.2s all linear",
            "&:hover": {
              boxShadow:
                " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              cursor: "pointer",
            },
        
          }}
        >
          <Skeleton
            animation="wave"
            variant="square"
            sx={{ height:{lg:'100px', md:'100px', sm:'100px', xs:'100px'} }}
          />

          <Box sx={{ p: 2 }}>
            <InputLabel sx={{ color: "#2b2b2b" }}>
              <Skeleton animation="wave" />
            </InputLabel>
            <Typography sx={{ fontWeight: 200, fontSize: "12px" }}>
              <Skeleton animation="wave" sx={{ width: "60%" }} />
            </Typography>
            <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
              <Rating readOnly value={5} size="small" sx={{ color: "#ccc" , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'10px'}}} />{" "}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // height: "270px",
            border: "1px solid #dedede",
            transition: "0.2s all linear",
            "&:hover": {
              boxShadow:
                " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              cursor: "pointer",
            },
          }}
        >
          {/* <Box
            sx={{
              height: "180px",
              backgroundImage: `url('${restaurant?.Photos[0]?.Link}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box align="right" sx={{}}>
              <IconButton>
                <img src={fav1} width={25} />
              </IconButton>
            </Box>
          
          </Box> */}
              <Box className="carousel-2" sx={{height:{lg:'180px', md:'180px', sm:'160px', xs:'120px'}}}>
      <Box className="carousel-images-2" sx={{zIndex:-1000}}>
        {images?.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl?.
Link
}
// height={"100%"}
// width={"100%"}
 effect="blur"
    onError={handleImageError}
            alt={` ${restaurant?.RestaurantName}`}
            className={`carousel-image-2 ${index === activeIndex ? 'active' : ''}`}
          />
        ))}


      </Box>


      <Box className="carousel-dots-2" sx={{mt:-2, }}>
        {images?.map((_, index) => (
          <Box
            key={index}
            className={`carousel-dot-2 ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Box>


    </Box>
    
          <Box sx={{ p: isMobile ? 1 : 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 , justifyContent:'space-between'}}>
              <InputLabel sx={{ color: "#2b2b2b" , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}>
                {restaurant?.RestaurantName}{" "}
              </InputLabel>
   <Box  sx={{}}>
              <IconButton sx={{p:0}}>
                <img src={fav1} width={isMobile ? 20 : 25} />
              </IconButton>
            </Box>
              {/* <OpenTag hoursString={restaurant?.OpenFrom}/> */}
            </Box>
            <Typography sx={{ fontWeight: 200, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
              {`${restaurant?.State && restaurant?.State + ","} ${
                restaurant?.Country
              }`}
            </Typography>
            <Box
              sx={{
                display: noRatings ? "none" : "flex",
                columnGap: 1,
                alignItems: "center",
              }}
            >
              <Rating
                precision={0.5}
                readOnly
                value={restaurant?.Rating}
                size="small"
                sx={{ color: "#BC172F" , fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'10px'}}}
              />{" "}
              <Typography sx={{ fontSize: "10px" }}>
                {restaurant?.Rating?.toFixed(1)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RestaurantCard;
