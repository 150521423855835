import { Box, Modal, LinearProgress } from '@mui/material';
import React , {useState } from 'react'




const style = {
  position: 'absolute',
  top: '0%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100vw",
  bgcolor: 'transparent',


  p: 4,
};
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#BC172F",
};

const Loader = () => {
    const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
   <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
<Box sx={style}>
   <Box sx={{ width: '100%' }}>
      <LinearProgress sx={{height:'5px'}}/>
    </Box>
</Box>
      </Modal>
  )
}

export default Loader