import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Footer/Footer";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputLabel,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  Button,
} from "@mui/material";
import Location from "../Components/Banner/Components/Location";
import Amenities from "../Components/FindRestaurants/Amenities";

import MapContainer from "../Components/Common/MapContainer";
import { filterRestaurant, findRestaurant } from "../../api";
import Loader from "../Components/Common/Loader";
import { IoStarSharp } from "react-icons/io5";
import serveImg from "../../Assets/images/placeholder.png";
import Lottie from "react-lottie";
import anime from "../../Assets/anime/zombie.json";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import Modal from "@mui/material/Modal";
import Calender from "../Components/Common/Calendar";
import TimePicker from "../Components/Banner/Components/TimePicker";
import GuestSize from "../Components/Banner/Components/GuestSize";
import { CloseOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 350, md: 350, sm: 350, xs: 300 },
  bgcolor: "background.paper",
maxHeight:'90vh',
overflow:'scroll',
  boxShadow: 24,
  p: 2,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const FindRestaurants = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { Text, DateParams, Time, Lat, Long } = queryParams;

  const [params, setParams] = useState(Text);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);

  const handleUpdateAddress = (long, lat) => {
    setLong(long);
    setLat(lat);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const [ReservationTime, setReservationTime] = useState("");
  const [ReservationDay, setReservationDay] = useState("");
  const [partySize, setPartySize] = useState("");

  const handleFIlter = async () => {
    handleClose();
    setIsLoading(true);
    await filterRestaurant(params, long, lat, ReservationTime, ReservationDay)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleFind = async () => {
    setIsLoading(true);
    await findRestaurant(params)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    setParams(Text);
  }, [Text]);
  useEffect(() => {
    handleFind();
  }, [params]);

  // console.log(params);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;

  useEffect(() => {
    setReservationTime(Time);
  }, [Time]);

  const handleUpdateTime = (newValue) => {
    setReservationTime(newValue);
  };

  const handleUpdatePartySize = (newValue) => {
    setPartySize(newValue);
  };

  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];

  const [date, setDate] = useState(toDate);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {/* {isLoading && <Loader />} */}
      <Navbar />
      <Box sx={{ mt: 9, border: "1px solid #d7d7d7" }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          }}
        >
          {isMobile ? (
            <>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Amenities />
                  <Box
                    sx={{
                      my: isMobile && 1,
                      border: "1px solid #d7d7d7",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.2s all linear",
                      "&:hover": {
                        bgcolor: "#f5f5f5",
                      },
                    }}
                    onClick={handleOpen}
                  >
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRight: "1px solid #d7d7d7",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Date</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {formatDate(date)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRight: "1px solid #d7d7d7",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Time</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {ReservationTime}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Guest</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {partySize} Guest
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 4,
                    height: "90px",
                    boxSizing: "border-box",
                  }}
                >
                  {/* <Location
                    handleUpdateAddress={handleUpdateAddress}
                    button={true}
                  /> */}
                  <Box
                    sx={{
                      border: "1px solid #d7d7d7",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.2s all linear",
                      "&:hover": {
                        bgcolor: "#f5f5f5",
                      },
                    }}
                    onClick={handleOpen}
                  >
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRight: "1px solid #d7d7d7",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Date</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {formatDate(date)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRight: "1px solid #d7d7d7",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Time</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {ReservationTime}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "110px",
                        boxSizing: "border-box",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "10px" }}>Guest</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        {partySize} Guest
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Amenities />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                // height: { lg: "70vh", md: "70vh", sm: "auto", xs: "auto" },
                width: "100%",
                py: 3,
                boxSizing: "border-box",
              }}
            >
              {isLoading ? (
                <>
                  {[1, 2, 3, 4]?.map((index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        columnGap: 2,
                        alignItems: "center",
                        width: "80%",
                        px: 1,
                        borderRadius: "10px",
                        height: "150px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Skeleton sx={{ width: "140px", height: "160px" }} />
                      <Box>
                        <Skeleton variant="text" sx={{ width: "160px" }} />
                        <Skeleton variant="text" sx={{ width: "130px" }} />
                        <Skeleton variant="text" sx={{ width: "90px" }} />
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  {!data || data?.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: {
                            lg: "80%",
                            md: "80%",
                            sm: "100%",
                            xs: "100%",
                          },
                        }}
                      >
                        <Lottie
                          options={defaultOptions}
                          width={200}
                          height={150}
                        />
                        <Typography
                          sx={{ mt: 2, fontWeight: 600, fontSize: "18px" }}
                        >
                          No Results
                        </Typography>
                        <Typography
                          sx={{ mt: 2, fontWeight: 300, fontSize: "12px" }}
                        >
                          Please modify your search and attempt again.
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {data
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((restaurant, index) => (
                          <Link to={`/restaurant/${restaurant?.Name}`}>
                            <Box
                              key={index}
                              sx={{
                                border: "1px solid #d7d7d7",
                                borderRadius: "10px",
                                width: {
                                  lg: "80%",
                                  md: "80%",
                                  sm: "100%",
                                  xs: "100%",
                                },
                                p: 1,
                                boxSizing: "border-box",
                                display: "flex",
                                columnGap: 2,
                                alignItems: "center",
                                transition: "0.2s all linear",
                                mb: 2,
                                cursor: "pointer",
                                "&:hover": {
                                  bgcolor: "#BC172F1a",
                                  border: "1px  solid #BC172F",
                                },
                              }}
                            >
                              <Avatar
                                src={
                                  restaurant?.ImageURL
                                    ? restaurant?.ImageURL
                                    : serveImg
                                }
                                variant="rounded"
                                sx={{ width: "100px", height: "100px" }}
                              />
                              <Box sx={{ height: "100px" }}>
                                <InputLabel
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: {
                                      lg: "16px",
                                      md: "16px",
                                      sm: "14px",
                                      xs: "14px",
                                    },
                                    color: "#151515",
                                  }}
                                >
                                  {restaurant?.Name}
                                </InputLabel>
                                <InputLabel
                                  sx={{
                                    mt: 1,
                                    fontSize: {
                                      lg: "14px",
                                      md: "14px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                  }}
                                >
                                  {restaurant?.RestaurantType}
                                </InputLabel>
                                <InputLabel
                                  sx={{
                                    mt: 0.5,
                                    fontSize: {
                                      lg: "12px",
                                      md: "12px",
                                      sm: "10px",
                                      xs: "10px",
                                    },
                                  }}
                                >
                                  {restaurant?.LGA && `${restaurant?.LGA},`}{" "}
                                  {restaurant?.State}
                                </InputLabel>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: 1,
                                    mt: 1,
                                  }}
                                >
                                  <IoStarSharp style={{ color: "#BC172F" }} />{" "}
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {restaurant?.Rating}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        ))}
                      <Grid
                        container
                        sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}
                      >
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={4}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#424242",
                              fontSize: "13px",
                              fontFamily: "outfit",
                            }}
                          >
                            {rowsText}
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} xs={8}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 2,
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                borderLeft: "1px solid #424242",
                                pl: { md: 4, xs: 2 },
                                ml: { md: 3, xs: 2 },
                              }}
                            >
                              <IconButton
                                disabled={startRow === 1}
                                onClick={handleChangePageLeft}
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  border: "1px solid #424242",
                                  "&.Mui-disabled": {
                                    border: "1px solid #4242421a",
                                  },
                                }}
                              >
                                <ChevronLeftRoundedIcon />
                              </IconButton>
                              <IconButton
                                disabled={endRow === data?.length}
                                onClick={handleChangePageRight}
                                sx={{
                                  ml: 4,
                                  width: "30px",
                                  height: "30px",
                                  border: "1px solid #424242",
                                  "&.Mui-disabled": {
                                    border: "1px solid #4242421a",
                                  },
                                }}
                              >
                                <ChevronRightRoundedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "block", md: "block", sm: "none", xs: "none" },
            }}
          >
            <Box sx={{ height: "70vh", width: "100%" }}>
              <MapContainer restaurantAddress={"Lekki Lagoss"} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="hide_scrollbar" sx={style}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handleClose}>
              <CloseOutlined/>
            </IconButton>
          </Box>
          <Box>
            <Calender
              past={true}
              onChildValue={(value) => {
                setDate(value);
              }}
            />
            <Box
              sx={{
                mt: 2,
                display: "grid",
                placeItems: "center",
                border: "1px solid #d7d7d7",
                borderRadius: "10px",
                py: 1,
              }}
            >
              <TimePicker handleUpdateTime={handleUpdateTime} />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "grid",
                placeItems: "center",
                border: "1px solid #d7d7d7",
                borderRadius: "10px",
                py: 1,
              }}
            >
              <GuestSize handleUpdatePartySize={handleUpdatePartySize} />
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              fullWidth
              sx={{ borderRadius: "10px", py: 1.5, mb: 3 }}
              onClick={handleFIlter}
            >
              Update Search
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FindRestaurants;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
