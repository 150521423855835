import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import RestaurantCard from "../Common/RestaurantCard";
import BlogCard from "../Common/BlogCard";
import BlogData from "../../../Assets/Dataa/BlogData";
import { Link } from "react-router-dom";
import Client from "../../../utils/Client";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Client.fetch(
      `*[_type == "event"] | order(date desc) {
    name,
    slug,
    blogType,
    date,
    description,
    image,
    details,
    readDuration
  }` // This will fetch the latest 5 blogs, change the number as needed
    )
      .then((data) => {
        setBlogs(data);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          mt: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #dadada",
            pb: 2,
          }}
        >
          <Box>
            <Typography
                 component={"h3"}
                variant="h3"
              sx={{
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontWeight: 500,
              }}
            >
              Blog
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
              }}
            >
              Find tips on how to enjoy the best dining experience in Lagos
            </Typography>
          </Box>
          <Box>
            <Link to="/blog">
              <Button
                endIcon={<MdArrowRightAlt />}
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
                }}
              >
                See More{" "}
              </Button>
            </Link>
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            {blogs?.slice(0, 4)?.map((blog, index) => (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link to={`/blog/${blog?.slug?.current}`} key={index}>
                  <BlogCard data={blog} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Blog;
