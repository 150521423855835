import React, { useState , useEffect} from 'react'
import Navbar from '../Components/Common/Navbar'
import Footer from '../Components/Footer/Footer'
import { Box, Typography, Grid } from '@mui/material'
import { getAllFeaturedRestaurants, getBestRestaurants } from '../../api'
import RestaurantCard from '../Components/Common/RestaurantCard'
import { Link } from 'react-router-dom'

const BestRestaurantMain = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)


    const handleGetFeatured = async () =>{
setIsLoading(true)
await getBestRestaurants() 
.then((res)=>{
setIsLoading(false)
  if(res?.data?.status){
    setData(res?.data?.result)
  }
}).catch((err)=>{
setIsLoading(false)
})
  }



  useEffect(()=>{
    handleGetFeatured()
  },[])
  return (
<>
<Navbar/>
    <Box sx={{ pt: 13, width: { xl: "75%", lg: "85%" }, margin: "0 auto" , pb:6}}>
<Box sx={{mt:4}}>
  <Typography variant='h1' component={"h1"} sx={{fontSize:'20px', fontWeight:700, color:'#151515'}}> Best restaurants in Lagos</Typography>
  <Typography sx={{mt:1, color:'#2f3441'}}>Make reservations at the best restaurants in Lagos</Typography>
</Box>

     <Box sx={{ mt: 3 }}>
      {
        isLoading ? (
              <Grid container spacing={3}>

            {
              [1,2,3,4,5,6,7,8]?.map((restaurant, index)=>(
   <Grid item lg={3} key={index}>
              <RestaurantCard loading/>
            </Grid>
              ))
            }
         
          
          </Grid>
        ):(
              <Grid container spacing={3}>

            {
              data?.map((restaurant, index)=>(
   <Grid item lg={3} key={index}>
    <Link to={`/restaurant/${restaurant?.RestaurantName}`}>
              <RestaurantCard  restaurant={restaurant}/>
              </Link>
            </Grid>
              ))
            }
         
          
          </Grid>
        )
      }
        </Box>
</Box>
<Footer/>

</>
  )
}

export default BestRestaurantMain