import { Box, Typography, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { getTransactions, getWallet } from "../../../../api";
import EmptyData from "../../Common/EmptyData";

const Payments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [balance, setBalance] = useState(null);
  const [transactions, setTransactions] = useState(null)

  const handleGetBalance = async () => {
    setIsLoading(true);
    await getWallet()
      .then((res) => {
        // console.log(res);
        setBalance(res?.data?.result?.AvailableBalance);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };
  const handleGetTransaction = async () => {
    setIsLoading(true);
    await getTransactions()
      .then((res) => {
        // console.log(res);
       setTransactions(res?.data?.result)
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetBalance();
    handleGetTransaction()
  }, []);
  return (
    <>
      <Box sx={{ height: "70vh", boxSizing: "border-box" }}>
        <Box
          sx={{
            background:
              " linear-gradient(to right top, #bc172f, #8d152e, #5e1526, #311119, #000000)",
            height: "200px",
            p: 7,
            boxSizing: "border-box",
            width:{lg:'40%', md:'40%', sm:'70%', xs:'100%'},
            margin:'0 auto',
            borderRadius:'0px 0px 8px 8px'
          }}
        >
          <Typography sx={{ color: "#fff", fontSize:'11px', textAlign:'center' }}>
            Wallet Available Balance
          </Typography>
          <Box
       
            sx={{ mt:2, display: "flex", columnGap: 2, alignItems: "center", justifyContent:'center' }}
          >
            {isLoading ? (
              <>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    mt: 0.5,
                    textShadow: "0 0 8px rgba(255, 255, 255, 0.7)",
                    color: "transparent",
                  }}
                >
                  N00, 000.00
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{ color: "#fff", fontSize: "30px", fontWeight: 700 }}
                >
                  <span style={{ fontSize: "15px" }}>₦ </span>
                  {balance?.toLocaleString()}.00
                </Typography>{" "}
                {/* <IconButton onClick={() => setShow(!show)} edge="end">
                  {show ? (
                    <VisibilityOutlinedIcon
                      sx={{ fontSize: "18px", color: "#fff" }}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      sx={{ fontSize: "18px", color: "#fff" }}
                    />
                  )}
                </IconButton> */}
              </>
            )}
          </Box>

          <Box align="center" sx={{mt:3}}>
            <Button disabled variant="contained" sx={{color:'#fff', borderRadius:'20px'}}>Add Funds</Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 , px:{lg:4, md:4, sm:2, xs:0}, pb:3}}>
          <Typography color="primary">Recent Transactions</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{border:'none'}}>
                    Date
                  </TableCell>
                  <TableCell align="center" sx={{border:'none'}}>
                    Details
                  </TableCell>
                  <TableCell align="center" sx={{border:'none'}}>
                    Status
                  </TableCell>
                  <TableCell align="center" sx={{border:'none'}}>
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              
            </Table>
          </TableContainer>

          <EmptyData text="No  transactions available "/>
        </Box>
      </Box>
    </>
  );
};

export default Payments;
