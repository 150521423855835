import { Box, Button, Divider, Grid, InputLabel, Typography } from '@mui/material'
import React from 'react'
import CustomInput from '../../Custom/CustomInput'

const HelpSupport = () => {
  return (
<>
<Box sx={{ bgcolor: "#f5f5f5", pt: 15, px: 4, height: "100vh", overflow:'scroll' }}>
  <Typography sx={{fontSize:'25px', fontWeight:500}}>Help and Feedback</Typography>
  <Typography sx={{mt:1, color:'#6f7f94'}}>Get help and support on how to use our services.</Typography>
  <Divider sx={{my:2}}/>

  <Box sx={{mt:3, bgcolor:'#fff', p:2, boxSizing:'border-box', border:'1px solid #dadada', borderRadius:'10px'}}>
  <Typography>Need to cancel an existing reservation?</Typography>
  <Typography sx={{mt:1, fontSize:'12px', color:'#717171'}}>In case you need to cancel a reservation that you have already made, kindly refer to the cancellation policy outlined in your confirmation email. Please note that restaurant policies regarding cancellation can vary, and they may also vary depending on the specific reservation.</Typography>
  <Typography sx={{mt:3}}>I would like to join as a restaurant owner</Typography>
  <Typography sx={{mt:1, fontSize:'12px', color:'#717171'}}>Great idea! With over 9,000 restaurant partners, we'd be thrilled to add you to our network. <a href='https://restaurant.reisty.com/' target='_blank' style={{color:'#BC172F', textDecoration:'underline'}}>Learn more here.</a></Typography>
  </Box>

  <Typography sx={{mt:4, color:'#565660'}}>If you have any additional questions</Typography>
    <Box sx={{mt:2, bgcolor:'#fff', p:2, boxSizing:'border-box', border:'1px solid #dadada', borderRadius:'10px'}}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box>
            <InputLabel>
            Full Name *
            </InputLabel>
            <CustomInput fullWidth placeholder="Full Name"/>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box>
            <InputLabel>
         Email Address *
            </InputLabel>
            <CustomInput fullWidth placeholder="Email Address"/>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <InputLabel>
        Message *
            </InputLabel>
            <CustomInput fullWidth multiline rows={3} placeholder="Your Message"/>
          </Box>
        </Grid>
 
        
      </Grid>
      <Button sx={{mt:2, borderRadius:'8px', py:1, px:4}} variant='contained' > Send  </Button>
    </Box>
</Box>
</>
  )
}

export default HelpSupport