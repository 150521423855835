import { Box, IconButton, ListItemButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MdArrowDropDown } from "react-icons/md";
import Popover from "@mui/material/Popover";

const GuestSize = ({handleUpdatePartySize}) => {
      const [anchorEl, setAnchorEl] = React.useState(null);
 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [guests, setGuests] = useState(1)

  useEffect(()=>{
  handleUpdatePartySize(guests)
  }, [])
  return (
<>
<Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'80%', cursor:'pointer'}}
      aria-describedby={id}
        variant="contained"
        onClick={handleClick}
>
<Box>
    <Typography sx={{color:'#717171',     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>Guest size</Typography>
    <Typography sx={{color:'#000', fontWeight:500,     fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>{guests} {guests === 1 ? "guest" : "guests"}</Typography>
</Box>

<IconButton>
<MdArrowDropDown />
</IconButton>
</Box>
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
     <Box className="hide_scrollbar" sx={{width:'200px', height:'200px', overflow:'scroll', py:1}}>
  {
    [1,2,3,4,5,6,7,8,9,10]?.map((item, index)=>(
      <ListItemButton selected={guests === item} key={index} onClick={()=>{
        setGuests(item)
        handleClose()
      }}>
        <Typography sx={{    fontSize:{lg:'12px', md:'12px', sm:'10px', xs:'10px'}}}>{item} {item === 1 ? "guest" : "guests"}</Typography>
      </ListItemButton>
    ))
  }
     </Box>
      </Popover>
</>
  )
}

export default GuestSize